import { api } from 'redux/api/api'


const TaskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    taskGetItems: builder.query({
      query: (params = null) => ({
        url: 'task/',
        params: params,
      }),
    }),
    taskGetDetail: builder.query({
      query: (id) => ({
        url: `task/${id}/`,
      }),
    }),
    taskGetForEdit: builder.query({
      query: (id) => ({
        url: `task/${id}/`,
      }),
    }),

    taskCreate: builder.mutation({
      query: (values) => ({
        url: 'task/',
        method: 'POST',
        body: values,
      }),
    }),
    taskUpdate: builder.mutation({
      query: (values) => ({
        url: `task/${values.id}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    taskDelete: builder.mutation({
      query: (id) => ({
        url: `task/${id}/`,
        method: 'DELETE',
      }),
    }),
    taskBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'task/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),
  }),
})


export default TaskApi;
