import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import { Navigation } from 'layout'
import Logo from 'components/Logo'
import UserPanel from 'components/UserPanel'

import "./styles.css"


const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className="app">
      <Layout.Sider
        className="appSider"
        collapsed={collapsed}
        breakpoint="lg"
        width="220"
        collapsedWidth="80"
        collapsible={false}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <Logo />
        
        <UserPanel />

        <Navigation />
      </Layout.Sider>

      <Layout className="appMain">
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
