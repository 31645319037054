import classNames from 'classnames'

import './styles.css'


export default function Logo(props) {
  const { className } = props;

  const cx = classNames(['logo', className]);

  return (
    <div className={cx}>
      <img className="logoImg" src="/logo192.png" alt="logo" />
      <span className="logoTitle">панель управления</span>
    </div>
  );
}
