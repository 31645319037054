import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from "react-router-dom"
import { Form, Dropdown, Input, Button } from 'antd'
import { SearchOutlined, FilterOutlined } from '@ant-design/icons'

import { getNoun } from 'utils/helpers'
import { getObjectParams, getDataQuery } from 'utils/urls'
import * as SmartFilterFields from './fields'

import './styles.css'


const SmartFilter = ({ fields, queryKey, onChange, totalLabels, count }) => {
  const location = useLocation();

  const [form] = Form.useForm();

  const pagination = useSelector(state => state[queryKey]?.pagination);

  const searchParams = new URLSearchParams(location.search);

  const objectSearchParams = useMemo(() => {
    return getObjectParams(location.search, queryKey);
  }, [location.search, queryKey]);

  const loadData = () => {
    if (onChange) {
      const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
      onChange({ dataQuery, searchString });
    }
  }

  const fieldsByName = (fields || []).reduce((res, item) => {
    res[item.fieldName] = item;
    return res;
  }, {});

  const onValuesChange = (changedValues, allValues) => {
    for (let fieldName in allValues) {
      const config = fieldsByName[fieldName];
      if (!config) continue;

      let value = allValues[fieldName];
      objectSearchParams.delete(fieldName);
      if (!value) continue;

      if (config.component?.getQueryParams) {
        value = config.component?.getQueryParams(value);
      }

      if (Array.isArray(value)) {
        value.forEach(v => {
          objectSearchParams.append(fieldName, v);
        })
      } else {
        objectSearchParams.set(fieldName, value);
      }
    }

    loadData();
  };

  useEffect(() => {
    const instance = (fields || []).reduce((result, item) => {
      const values = objectSearchParams.getAll(item.fieldName);
      result[item.fieldName] = item.component.getValue(values);
      return result;
    }, {});
    form.setFieldsValue(instance);
  }, [form, objectSearchParams, fields]);

  const onSearchChange = (e) => {
    const value = e.target.value;
    if (value) {
      objectSearchParams.set('search', value);
    } else {
      objectSearchParams.delete('search');
    }

    loadData();
  };


  const allCount = pagination?.count || count;


  return (
    <div className="smartFilter">
      <Input
        className="smartFilterSearch"
        placeholder="Поиск и фильтры"
        value={objectSearchParams.get('search')}
        allowClear={true}
        onChange={onSearchChange}
        prefix={<SearchOutlined />}
        size="large"
      />

      {fields &&
        <Dropdown
          dropdownRender={(menu) => (
            <Form className="smartFilterForm" layout="vertical" form={form} onValuesChange={onValuesChange}>
              {fields.map(item => (
                <item.component key={item.fieldName} name={item.fieldName} label={item.label} {...item.params} />
              ))}
            </Form>
          )}
          trigger="click"
        >
          <Button className="smartFilterBtn">
            <FilterOutlined /> Фильтры
          </Button>
        </Dropdown>
      }

      {allCount !== undefined &&
        <div className="smartFilterTotal">
          {allCount} {getNoun(allCount, totalLabels || ['элемент', 'элемента', 'элементов'])}
        </div>
      }
    </div>
  );
};

SmartFilter.getFieldConfig = (fieldName, label, component, params) => {
  return { fieldName, label, component, params }
}

SmartFilter.FilterBool = SmartFilterFields.FilterBool;
SmartFilter.FilterCheckboxGroup = SmartFilterFields.FilterCheckboxGroup;
SmartFilter.FilterDateRange = SmartFilterFields.FilterDateRange;
SmartFilter.FilterSelect = SmartFilterFields.FilterSelect;
SmartFilter.FilterNumber = SmartFilterFields.FilterNumber;

export default SmartFilter;
