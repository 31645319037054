import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { Button } from 'antd'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'

import { DetailPage } from 'layout'
import { Fieldset, SmartFilter, SmartFilterValues } from 'components/common'

import { API_URL } from 'config'
import TaskListApi from 'models/TaskList/api'
import { TaskListForm, TaskListUploadForm, TaskListDetail } from 'models/TaskList/components'
import { TaskTable, TaskForm } from 'models/Task/components'


const taskFilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const TaskListDetailPage = () => {
  const taskTableRef = useRef(null);
  const { taskListSlug } = useParams();

  const detail = useSelector(state => state.taskList.detail);
  const [getDetail, getDetailProps] = TaskListApi.endpoints.taskListGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = TaskListApi.endpoints.taskListDelete.useMutation();

  const [taskEditFormState, setTaskEditFormState] = useState({});

  const onTaskEdit = (taskId) => {
    setTaskEditFormState({ visible: true, objectId: taskId });
  }

  const onTaskEditFormClose = (isUpdated) => {
    setTaskEditFormState({});
    if (isUpdated) {
      taskTableRef.current.loadData({});
    }
  }


  const [uploadFormState, setUploadFormState] = useState({});

  const onUpload = () => {
    setUploadFormState({ visible: true, objectId: taskListSlug });
  }

  const onUploadFormClose = (isUpdated) => {
    setUploadFormState({});
    if (isUpdated) {
      getDetail(taskListSlug);
      taskTableRef.current.loadData({});
    }
  }

  const onFilterChange = ({ dataQuery, searchString }) => {
    taskTableRef.current.loadData({ dataQuery, searchString });
  }

  const linkUrl = `${API_URL}/api/task/${detail?.slug}/?download=1`; 

  return (
    <DetailPage
      objectId={taskListSlug}
      detail={detail}
      title={`Список заданий «${taskListSlug}»`}
      showBack={true}
      basePath={'/tasklist/'}
      breadcrumbs={[
        { title: 'Списки заданий', path: '/tasklist/' },
      ]}

      mainActions={[
        { name: 'download', type: 'default', icon: <DownloadOutlined />, label: 'Скачать', action: () => window.location = linkUrl },
        { name: 'upload', type: 'default', icon: <UploadOutlined />, label: 'Загрузить файл заданий', action: () => onUpload() }
      ]}

      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}

      render={(editFormState, onFormClose) => (
        <>
          <TaskListDetail detail={detail} />

          <TaskListForm onClose={onFormClose} {...editFormState} />
          <TaskListUploadForm onClose={onUploadFormClose} {...uploadFormState} />

          <Fieldset
            title="Задания"
            middle={<SmartFilter queryKey="task" onChange={onFilterChange} fields={taskFilterFields} />}
            end={
              <Button type="primary" onClick={() => onTaskEdit()}>Добавить задание</Button>
            }
          >
            <SmartFilterValues queryKey="task" onChange={onFilterChange} fields={taskFilterFields} />

            <TaskTable
              ref={taskTableRef}
              filterParams={{ listSlug: taskListSlug }}
              onEdit={onTaskEdit}

            />
          </Fieldset>

          <TaskForm taskListSlug={taskListSlug} program={detail?.program} onClose={onTaskEditFormClose} {...taskEditFormState} />
        </>
      )}
    />
  );
}

export default TaskListDetailPage
