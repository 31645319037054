import { useMemo } from 'react'
import classNames from 'classnames'

import { isDict } from 'utils/helpers'

import styles from './error.module.css'


const FormError = ({ className, error }) => {
  const errors = useMemo(() => {
    if (!error?.data) return '';
    const errorDetail = error.data?.detail;
    if (isDict(errorDetail)) {
      return null;
    } else if (Array.isArray(errorDetail)) {
      return errorDetail.map((item, idx) => <div key={`e${idx}`}>{item?.loc && item?.loc[1]}: {item?.msg}</div>);
    }  else if (typeof errorDetail === 'string' || errorDetail instanceof String) {
      return errorDetail;
    }
    return 'Не предвиденная ошибка';
  }, [error]);

  const cx = classNames([
    styles.error,
    className,
  ]);

  if (!errors) return null;

  return (
    <div className={cx}>{errors}</div>
  );
};

export default FormError;
