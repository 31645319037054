import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, SelectField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {

  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Домен" name="domain" required={true} />

        <SelectField
          label="HTTP схема"
          name="http_schema"
          required={true}
          options={[
            { value: 'https', label: 'https' },
            { value: 'http', label: 'http' },
          ]}
        />
      </Col>

      <Col span={8}>

      </Col>
    </Row>
  );
};

export default MainFieldset;
