import { FormFields } from 'components/common'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {

  return (
    <>
      <TextField label="Домен" name="domain" required={true} disabled={true} />

      <TextField label="URL" name="url" required={true} />

      <SwitchField label="Активно" name="isActive" />
    </>
  );
};

export default MainFieldset;
