import { useEffect, useMemo } from 'react'
import { Spin } from 'antd'


const ListLoadComponent = ({ getList, getListProps, filter, render, onLoaded }) => {
  const items = useMemo(() => {
    if (getListProps.isSuccess) {
      return getListProps.data.results || getListProps.data;
    }
    return []
  }, [getListProps.isSuccess, getListProps.data])

  useEffect(() => {
    const params = { for_select: true, ...filter };
    getList(params);
  }, [filter, getList]);

  useEffect(() => {
    if (getListProps.isSuccess) {
      onLoaded && onLoaded(getListProps.data.results);
    }
  }, [onLoaded, getListProps.isSuccess, getListProps.data]);

  return <Spin spinning={getListProps.isLoading}>{render({ items })}</Spin>;
};

export default ListLoadComponent;
