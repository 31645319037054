import { FormFields } from 'components/common'

const { TextField, TextareaField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {

  return (
    <>
      <TextField label="Название" name="name" required={true} />

      <TextareaField label="Содержание" name="content" required={true} />
    </>
  );
};

export default MainFieldset;
