import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, DateTimeValue, Image, ImageGallery, OnOff } from 'components/common'
import SiteApi from 'models/Site/api'


const SiteDetail = ({ detail }) => {
  const [itemUpdate, itemUpdateProps] = SiteApi.endpoints.siteUpdate.useMutation();
  const [getDetail] = SiteApi.endpoints.siteGetDetail.useLazyQuery();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getDetail(detail?.id);
    }
    // eslint-disable-next-line
  }, [detail?.id, itemUpdateProps.isSuccess]);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Категория">{detail?.category.name}</Descriptions.Item>

            <Descriptions.Item span={3} label1="Лого" className="pad0">
              <Image src={detail?.icon[0]?.url} width={56} height={56} style={{ margin: '-8px' }} />
            </Descriptions.Item>

            <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

            <Descriptions.Item label="Slug">{detail?.slug}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Контакты">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Адрес">{detail?.address}</Descriptions.Item>
            <Descriptions.Item label="Телефон">{detail?.phone}</Descriptions.Item>
            <Descriptions.Item label="Режим работы">{detail?.worktime}</Descriptions.Item>
            <Descriptions.Item label="Как пройти">
              <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.pathText }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Текст">
          <Descriptions>
            <Descriptions.Item label="">
              <div style={{ overflowX: 'scroll', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} fieldName="isActive" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
            </Descriptions.Item>

            <Descriptions.Item label="Сортировка">{detail?.sort}</Descriptions.Item>

            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeValue date={detail?.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Галерея">
          <ImageGallery images={detail?.images} />
        </Fieldset>
      </Col>
    </Row>
  );
}

export default SiteDetail
