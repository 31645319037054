import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import { TableList, TableColumns, PropertyList } from 'components/common'
import WalkSiteApi from 'models/WalkSite/api'
import WalkSiteValue from '../WalkSiteValue'
import { CountryValue } from 'models/Country/components'
import { CityValue } from 'models/City/components'
import { CategoryValue } from 'models/Category/components'


const defaultColumnList = ['domain', 'geo', 'categories', 'traffic', 'ipv6', 'syncedAt', 'isActive', 'actions'];


const WalkSiteTable = forwardRef(({ columnList, onEdit, filterParams }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.walkSite.items);
  const pagination = useSelector(state => state.walkSite.pagination);

  const countryByCode = useSelector(state => state.country.byCode);
  const cityByCode = useSelector(state => state.city.byCode);

  const [getList, getListProps] = WalkSiteApi.endpoints.walkSiteGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = WalkSiteApi.endpoints.walkSiteUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = WalkSiteApi.endpoints.walkSiteDelete.useMutation();
  const [batchDelete, batchDeleteProps] = WalkSiteApi.endpoints.walkSiteBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    domain: {
      title: 'Название',
      sorter: true,
      render: (val, item) => {
        const schema = item?.https ? 'https' : 'http';
        const url = `${schema}://${item?.domain}`;
        return (
          <>
            <WalkSiteValue detail={item} asLink={true} isBack={true} />
            <Button size='small' style={{ marginLeft: '8px' }} onClick={() => { window.open(url, '_blank', 'noreferrer') }}>
              <LinkOutlined />
            </Button>
            <div style={{ color: '#9f9f9f', fontSize: '12px', lineHeight: '1.25', marginTop: '4px' }}>{item.name}</div>
          </>
        )
      },
    },
    geo: {
      title: 'ГЕО',
      sorter: false,
      width: 190,
      render: (val, item) => {
        const country = countryByCode[item.countryCode] || { code: item.countryCode };
        const city = item.cityCode ? cityByCode[item.cityCode] || { code: item.cityCode } : null;
        return (
          <PropertyList
            mode1="compact"
            items={[
              { label: 'Страна', value: <CountryValue detail={country} asLink={true} isBack={true} /> },
              { label: 'Город', value: city && <CityValue detail={city} asLink={true} isBack={true} /> },
            ]}
          />
        )
      },
    },
    categories: {
      title: 'Категории',
      sorter: false,
      width: 190,
      render: (val, item) => (
        <div style={{ fontSize: '12px', lineHeight: '1.25' }}>
          {val.map((v, idx) => <CategoryValue key={`category${v}`} style={{ marginBottom: '4px' }} detail={{ code: v }} asLink={true} isBack={true} />)}
        </div>
      ),
    },
    ipv6: TableColumns.getColumnBool({ title: 'IPV6', fieldName: 'ipv6' }),
    syncedAt: TableColumns.getColumnDateTime({ title: 'Синхр.', fieldName: 'syncedAt', sorter: true }),
    traffic: { title: 'Посещяемость', sorter: true, width: 150 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "domain", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "domain" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="walkSite"
      rowKey="domain"

      filterParams={filterParams}
      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
    />
  );
});

export default WalkSiteTable;
