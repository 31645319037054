import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import WalkSiteListApi from 'models/WalkSiteList/api'
import MainFieldset from './MainFieldset'


const WalkSiteListForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.walkSiteList.detailForEdit);
  const [getDetail, getDetailProps] = WalkSiteListApi.endpoints.walkSiteListGetForEdit.useLazyQuery();
  const [create, createProps] = WalkSiteListApi.endpoints.walkSiteListCreate.useMutation();
  const [update, updateProps] = WalkSiteListApi.endpoints.walkSiteListUpdate.useMutation();

  const onFinish = (values) => {
    const categories = values.categories.reduce((res, item) => {
      res[item.category] = item.percent;
      return res
    }, {});

    let filters = {};

    if (values.cityCode__in && values.cityCode__in.length > 0) {
      filters.cityCode__in = values.cityCode__in;
    }

    if (values.traffic__gt) {
      filters.traffic__gt = values.traffic__gt;
    }

    if (typeof values.ym__isnotnull === "boolean" || values.ym__isnotnull === "true" || values.ym__isnotnull === "false") {
      filters.ym__isnotnull = values.ym__isnotnull;
    }

    if (typeof values.ipv6 === "boolean" || values.ipv6 === "true" || values.ipv6 === "false") {
      filters.ipv6 = values.ipv6;
    }

    let data = {
      name: values.name,
      isActive: values.isActive,
      categories,
      filters
    };
    if (objectId) {
      data.slug = objectId;
    }
    console.log(data);
    if (objectId) {
      update(data);
    } else {
      create(data);
    }
  };

  const initialValues = {
    sort: 100,
    ...initials
  };

  return (
    <ModalForm
      name="walkSiteList"
      withTabs={false}
      title={`Редактирование списка заданий ${objectId || ''}`}
      width={700}
      visible={visible}

      primaryField="slug"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        const  categories = Object.keys(detail?.categories || {}).map(category => ({ category, percent: detail.categories[category]}));

        return { ...detail, categories, ...detail?.filters }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default WalkSiteListForm
