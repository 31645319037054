import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { Button } from 'antd'

import { DetailPage } from 'layout'
import { Fieldset, SmartFilter, SmartFilterValues } from 'components/common'

import WalkSiteApi from 'models/WalkSite/api'
import { WalkSiteLinkTable, WalkSiteLinkForm } from 'models/WalkSiteLink/components'
import { WalkSiteForm, WalkSiteDetail } from 'models/WalkSite/components'


const linkFilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const WalkSiteDetailPage = () => {
  const linkTableRef = useRef(null);
  const { walkSiteDomain } = useParams();

  const detail = useSelector(state => state.walkSite.detail);
  const [getDetail, getDetailProps] = WalkSiteApi.endpoints.walkSiteGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = WalkSiteApi.endpoints.walkSiteDelete.useMutation();

  const [linkEditFormState, setLinkEditFormState] = useState({});

  const onLinkEdit = (linkUrl, link) => {
    setLinkEditFormState({ visible: true, objectId: linkUrl, link });
  }

  const onLinkEditFormClose = (isUpdated) => {
    setLinkEditFormState({});
    if (isUpdated) {
      getDetail(walkSiteDomain)
    }
  }

  const onFilterChange = ({ dataQuery, searchString }) => {
    linkTableRef.current.loadData({ dataQuery, searchString });
  }

  const linksCount = Object.keys(detail?.links || {}).length;

  return (
    <DetailPage
      objectId={walkSiteDomain}
      detail={detail}
      title={`${detail?.domain}`}
      showBack={true}
      basePath={'/walksite/'}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      render={(editFormState, onFormClose) => (
        <>
          <WalkSiteDetail detail={detail} />

          <WalkSiteForm onClose={onFormClose} {...editFormState} />

          <Fieldset
            title="Ссылки"
            middle={<SmartFilter queryKey="walkSiteLink" onChange={onFilterChange} fields={linkFilterFields} count={linksCount} />}
            end={
              <Button type="primary" onClick={() => onLinkEdit()}>Добавить ссылку</Button>
            }
          >
            <SmartFilterValues queryKey="walkSiteLink" onChange={onFilterChange} fields={linkFilterFields} />

            <WalkSiteLinkTable
              ref={linkTableRef}
              domain={walkSiteDomain}
              links={detail?.links}
              filterParams={{ domain: walkSiteDomain }}
              onEdit={onLinkEdit}
            />
          </Fieldset>

          {detail?.domain &&
            <WalkSiteLinkForm domain={walkSiteDomain} walkSite={detail} onClose={onLinkEditFormClose} {...linkEditFormState} />}
        </>
      )}
    />
  );
}

export default WalkSiteDetailPage
