import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { Button } from 'antd'

import { DetailPage } from 'layout'
import { Fieldset, SmartFilter, SmartFilterValues } from 'components/common'

import CountryApi from 'models/Country/api'
import { CountryForm, CountryDetail } from 'models/Country/components'
import { CityTable, CityForm } from 'models/City/components'


const cityFilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const CountryDetailPage = () => {
  const cityTableRef = useRef(null);
  const { countryCode } = useParams();

  const detail = useSelector(state => state.country.detail);
  const [getDetail, getDetailProps] = CountryApi.endpoints.countryGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = CountryApi.endpoints.countryDelete.useMutation();

  const [cityEditFormState, setCityEditFormState] = useState({});

  const onCityEdit = (cityCode) => {
    setCityEditFormState({ visible: true, objectId: cityCode });
  }

  const onCityEditFormClose = (isUpdated) => {
    setCityEditFormState({});
    if (isUpdated) {
      cityTableRef.current && cityTableRef.current.loadData({});
    }
  }

  const onFilterChange = ({ dataQuery, searchString }) => {
    cityTableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <DetailPage
      objectId={countryCode}
      detail={detail}
      title={`${detail?.name}`}
      showBack={true}
      basePath={'/country/'}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      render={(editFormState, onFormClose) => (
        <>
          <CountryDetail detail={detail} />

          <CountryForm onClose={onFormClose} {...editFormState} />

          <Fieldset
            title="Города"
            middle={<SmartFilter queryKey="city" onChange={onFilterChange} fields={cityFilterFields} />}
            end={
              <Button type="primary" onClick={() => onCityEdit()}>Добавить город</Button>
            }
          >
            <SmartFilterValues queryKey="city" onChange={onFilterChange} fields={cityFilterFields} />
            
            <CityTable
              ref={cityTableRef}
              filterParams={{ countryCode: countryCode }}
              onEdit={onCityEdit}
              topActions={[
                <Button type="primary" onClick={() => onCityEdit()}>Добавить город</Button>
              ]}
            />
          </Fieldset>

          <CityForm countryCode={countryCode} onClose={onCityEditFormClose} {...cityEditFormState} />
        </>
      )}
    />
  );
}

export default CountryDetailPage
