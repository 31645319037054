import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  detail: null,
  detailForEdit: null,
};

export const taskSlice = createSlice({
  initialState,
  name: 'task',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.taskGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.taskGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.taskGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.taskGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.taskGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.taskUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default taskSlice.reducer;
