import { DetailValue } from 'components/common'


const ConfigListValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue 
        linkUrl={asLink && `/configlist/${detail?.slug}/`} 
        isBack={isBack} 
        render={() => (
          <div className="valueFlex">
            {detail?.name || '-'}
          </div>
        )}
      />
    </>
  );
};


export default ConfigListValue
