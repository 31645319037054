export function getFullName(obj) {
  let name = obj.last_name;
  if (obj.first_name) name += ` ${obj.first_name}`;
  if (obj.middle_name) name += ` ${obj.middle_name}`;
  return name;
}

export function getFullNameOrEmail(obj) {
  if (!obj) return '';
  let name = '';
  if (obj.first_name) name += ` ${obj.first_name}`;
  if (obj.middle_name) name += ` ${obj.middle_name}`;
  if (obj.last_name) name += ` ${obj.last_name}`;
  name = name.trim();
  if (!name && obj.phone && !obj.phone.startsWith('@')) name = `${obj.phone}`;
  if (!name && obj.email) name = `${obj.email}`;
  if (!name) name = `${obj.username}`;
  return name;
}


export function getShortName(obj) {
  if (!obj) return null;
  let name = obj.last_name;
  if (obj.first_name) name += ` ${obj.first_name[0]}.`;
  if (obj.middle_name) name += ` ${obj.middle_name[0]}.`;
  return name;
}

export function getNoun(number, labels) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return labels[2];
  }
  n %= 10;
  if (n === 1) {
    return labels[0];
  }
  if (n >= 2 && n <= 4) {
    return labels[1];
  }
  return labels[2];
}

export function getToken() {
  if ('localStorage' in window) {
    try {
      if (window.localStorage !== null) {
        return window.localStorage.getItem('token');
      }
    } catch (e) {
    }
  }
  return null;
}

export function setToken(token) {
  if ('localStorage' in window) {
    try {
      if (window.localStorage !== null) {
        return window.localStorage.setItem('token', token);
      }
    } catch (e) {
    }
  }
  return null;
}


export function removeToken() {
  if ('localStorage' in window) {
    try {
      if (window.localStorage !== null) {
        return window.localStorage.removeItem('token');
      }
    } catch (e) {
    }
  }
  return null;
}


export function dictIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}


export function isDict(v) {
  return typeof v === 'object' && v !== null && !(v instanceof Array) && !(v instanceof Date);
}


export function dictIsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}


export function arrayIsEqual(array1, array2) {
  // if the other array is a falsy value, return
  if (!array2)
    return false;

  // compare lengths - can save a lot of time
  if (array1.length !== array2.length)
    return false;

  for (let i = 0, l = array1.length; i < l; i++) {
    if (array1[i].id) {
      if (array1[i].id !== array2[i].id) {
        return false;
      }
    } else {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  }
  return true;
}


export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
