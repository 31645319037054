import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import CityApi from 'models/City/api'
import MainFieldset from './MainFieldset'


const CityForm = ({ visible, objectId, initials, onClose, countryCode }) => {
  const detailForEdit = useSelector(state => state.city.detailForEdit);
  const [getDetail, getDetailProps] = CityApi.endpoints.cityGetForEdit.useLazyQuery();
  const [create, createProps] = CityApi.endpoints.cityCreate.useMutation();
  const [update, updateProps] = CityApi.endpoints.cityUpdate.useMutation();

  const onFinish = (values) => {
    console.log(values);
    if (objectId) {
      update(values);
    } else {
      create(values);
    }
  };

  const initialValues = {
    ...initials,
    countryCode,
  };

  return (
    <ModalForm
      name="city"
      withTabs={true}
      title={`Редактирование города ${objectId || ''}`}
      width={700}
      visible={visible}

      objectId={objectId}
      primaryField="code"
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initialValues} />
    </ModalForm>
  );
}


export default CityForm
