import { api } from 'redux/api/api'


const CategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    categoryGetItems: builder.query({
      query: (params = null) => ({
        url: 'category/',
        params: params,
      }),
    }),
    categoryGetDetail: builder.query({
      query: (code) => ({
        url: `category/${code}/`,
      }),
    }),
    categoryGetForEdit: builder.query({
      query: (code) => ({
        url: `category/${code}/?forEdit=1`,
      }),
    }),

    categoryGetItemsForSelect: builder.query({
      query: (params = null) => ({
        url: 'category/',
        params: params,
      }),
    }),

    categoryCreate: builder.mutation({
      query: (values) => ({
        url: 'category/',
        method: 'POST',
        body: values,
      }),
    }),
    categoryUpdate: builder.mutation({
      query: (values) => ({
        url: `category/${values.slug}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    categoryDelete: builder.mutation({
      query: (slug) => ({
        url: `category/${slug}/`,
        method: 'DELETE',
      }),
    }),
    categoryBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'category/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),

    categoryCombine: builder.mutation({
      query: (values) => ({
        url: 'category/combine/',
        method: 'POST',
        body: values,
      }),
    }),
  }),
});

export default CategoryApi;
