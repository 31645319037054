import { api } from 'redux/api/api'


const WalkSiteListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    walkSiteListGetItems: builder.query({
      query: (params = null) => ({
        url: 'walksitelist/',
        params: params,
      }),
    }),
    walkSiteListGetDetail: builder.query({
      query: (id) => ({
        url: `walksitelist/${id}/`,
      }),
    }),
    walkSiteListGetForEdit: builder.query({
      query: (id) => ({
        url: `walksitelist/${id}/`,
      }),
    }),

    walkSiteListGetItemsForSelect: builder.query({
      query: (params = null) => ({
        url: 'walksitelist/',
        params: {
          ...params,
          for_select: true,
        },
      }),
    }),

    walkSiteListCreate: builder.mutation({
      query: (values) => ({
        url: 'walksitelist/',
        method: 'POST',
        body: values,
      }),
    }),
    walkSiteListUpdate: builder.mutation({
      query: (values) => ({
        url: `walksitelist/${values.slug}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    walkSiteListDelete: builder.mutation({
      query: (id) => ({
        url: `walksitelist/${id}/`,
        method: 'DELETE',
      }),
    }),
    walkSiteListBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'walksitelist/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),
  }),
})


export default WalkSiteListApi;
