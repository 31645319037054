import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Tabs } from 'antd'

import './styles.css'


const TopTabs = ({ basePath, tabs, onTabChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(() => {
    const path = location.pathname.replace(basePath, '');
    let i = 0, n = tabs.length;
    while (i < n) {
      const tab = tabs[i];
      const key = tab.key.replace(basePath, '');
      if (path === key && key === "") return tab.key;
      if (key && path.startsWith(key.replace(basePath, ''))) {
        return tab.key;
      }
      i++;
    }
  }, [location.pathname, basePath, tabs]);

  const onChange = (key) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      const url = `${key}`;
      navigate(url);
    }
  }

  return (
    <Tabs className="topTabs" activeKey={activeTab} items={tabs} onChange={onChange} type="card" />
  );
}


export default TopTabs;
