import { Dropdown, Popconfirm } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons"
import classNames from 'classnames'

import './styles.css'


const DefaultActionByName = {
  add: {
    label: 'Добавить',
    icon: <PlusOutlined />,
  },
  edit: {
    label: 'Редактировать',
    icon: <EditOutlined />,
  },
  delete: {
    label: 'Удалить',
    icon: <DeleteOutlined />,
    confirm: {
      title: 'Уверены, что хотите удалить?',
      okText: 'Удалить',
      cancelText: 'Отмена'
    }
  },
}

const ActionButton = ({ className, title, icon, onClick, actions }) => {
  const cx = classNames({
    'actionButton': true,
    'actionButton_isSingle': !icon && !title,
    [className]: true,
  });

  const items = actions.map(item => {
    const defaultItem = DefaultActionByName[item.name];

    const confirm = item.confirm || defaultItem.confirm;
    const icon = item.icon || defaultItem.icon;
    const label = item.label || defaultItem.label;

    const btn = confirm ?
      <Popconfirm
        placement="bottomRight"
        title={confirm.title}
        okText={confirm.okText}
        cancelText={confirm.cancelText}
        onConfirm={() => item.action()}
        onClick={e => e.stopPropagation()}
      >
        {label}
      </Popconfirm>
      :
      <div onClick={() => item.action()}>{label}</div>;


    return {
      key: item.name,
      icon,
      label: btn,
      danger: !!confirm,
    }
  });

  const menuProps = {
    items,
  };

  if (!icon && !title) {
    return (
      <Dropdown className={cx} menu={menuProps} trigger="click">
        <MoreOutlined />
      </Dropdown>
    )
  }

  return (
    <Dropdown.Button className={cx} menu={menuProps} onClick={() => onClick && onClick()} trigger="click">
      {icon}{title}
    </Dropdown.Button>
  );
};

ActionButton.DefaultActionByName = DefaultActionByName;

export default ActionButton;
