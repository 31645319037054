import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import CategoryApi from 'models/Category/api'
import CategoryValue from '../CategoryValue'


const defaultColumnList = ['name', 'walkSiteCount', 'actions'];


const CategoryTable = forwardRef(({ columnList, onEdit, onCombine }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.category.items);
  const pagination = useSelector(state => state.category.pagination);

  const [getList, getListProps] = CategoryApi.endpoints.categoryGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = CategoryApi.endpoints.categoryUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = CategoryApi.endpoints.categoryDelete.useMutation();
  const [batchDelete, batchDeleteProps] = CategoryApi.endpoints.categoryBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => <CategoryValue detail={item} asLink={false} isBack={true} />,
    },
    walkSiteCount: TableColumns.getColumnNumber({ title: 'Кол-во сайтов', fieldName: 'walkSiteCount', sorter: false }),
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "slug", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "slug" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="category"
      rowKey="slug"

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
      rowActions={[
        {
          title: 'Объединить',
          icon: 'join',
          type: 'default',
          action: (selected) => onCombine(selected),
        },
      ]}
    />
  );
});

export default CategoryTable;
