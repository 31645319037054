import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, DateTimeValue, TableColumns } from 'components/common'
import TaskApi from 'models/Task/api'
import TaskValue from '../TaskValue'


const defaultColumnList = ['domain', 'keyword', 'num', 'sclick', 'params', 'isActive', 'actions'];


const TaskTable = forwardRef(({ columnList, filterParams, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.task.items);
  const pagination = useSelector(state => state.task.pagination);

  const [getList, getListProps] = TaskApi.endpoints.taskGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = TaskApi.endpoints.taskUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = TaskApi.endpoints.taskDelete.useMutation();
  const [batchDelete, batchDeleteProps] = TaskApi.endpoints.taskBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    domain: {
      title: 'Сайт',
      sorter: true,
      render: (val, item) => (
        <>
          <TaskValue detail={item} asLink={false} isBack={true} />
          {item.additionalDomains && item.additionalDomains.length > 0 &&
            <div className='additionalDomains'>
              <div className='additionalDomainsTitle'>Доп. запросы:</div>
              <div className='additionalDomainsBody'>
                {item.additionalDomains.map(domain => (
                  <TaskValue key={`domain${domain}`} detail={{ domain }} asLink={false} isBack={true} />
                ))}
              </div>
            </div>
          }
        </>
      ),
    },
    keyword: {
      title: 'Запрос',
      sorter: true,
    },
    num: {
      title: 'Кол-во в сутки',
      sorter: true,
      width: 100,
      render: (val, item) => (item.program === 'webvisitor' && item.numFrom !== null ? `${item.numFrom},${val}` : val),
    },
    sclick: {
      title: 'Склик',
      sorter: true,
      width: 80,
    },
    params: {
      title: 'Параметры',
      sorter: true,
      width: 200,
      render: (val, item) => <span style={{wordBreak: 'break-all'}}>{val}</span>
    },
    listSlug: {
      title: 'Список заданий',
      sorter: true,
      width: 150,
    },
    createdAt: {
      title: 'Создано',
      sorter: true,
      width: 120,
      render: (val, item) => <DateTimeValue date={val} />,
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "id", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "id" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      title="Задания"
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="task"
      rowKey="id"

      filterParams={filterParams}
      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
    />
  );
});

export default TaskTable;
