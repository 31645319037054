const ENV = {
  local: {
    apiUrl: 'http://localhost:8000',
  },
  production: {
    apiUrl: 'https://pf-api.treeweb.top',
  },
};

const urls = ENV['production'];

export const API_URL = urls.apiUrl;
