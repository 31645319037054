import { Form, InputNumber } from 'antd'


const FilterNumber = ({ label, name, size }) => {
  return (
    <Form.Item name={name} label={label}>
      <InputNumber size={size} />
    </Form.Item>
  );
};

FilterNumber.getValue = (values) => {
  if (Array.isArray(values)) values = values[0];
  return values;
}


export default FilterNumber;
