import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { SiteSelectField } from 'models/Site/components'

const { TextField, NumberField } = FormFields;


const MainFieldset = ({ detailForEdit, initials, program }) => {

  return (
    <>
      <SiteSelectField label="Домен" name="domain" required={true} />

      {program === 'webvisitor' && <TextField label="Доп. запросы" name="additionalDomains" required={false} />}

      <TextField label="Запрос" name="keyword" required={true} />

      <Row gutter={32}>
        {program === 'webvisitor' &&
          <Col span={6}>
            <NumberField label="Кол-во в сутки от" name="numFrom" required={false} />
          </Col>
        }
        <Col span={6}>
          <NumberField label="Кол-во в сутки до" name="num" required={true} />
        </Col>
      </Row>


      {program === 'webvisitor' && <NumberField label="Склик" name="sclick" required={false} />}

      <TextField label="Параметры" name="params" required={false} />
    </>
  );
};

export default MainFieldset;
