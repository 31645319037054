import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import ConfigListApi from 'models/ConfigList/api'
import MainFieldset from './MainFieldset'


const ConfigListUploadForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.configList.detailForEdit);
  const [getDetail, getDetailProps] = ConfigListApi.endpoints.configListGetForEdit.useLazyQuery();
  const [update, updateProps] = ConfigListApi.endpoints.configListUpload.useMutation();

  const onFinish = (values) => {
    const data = new FormData();
    data.append("file", values.file[0].originFileObj);
    update({ slug: objectId, data });
  };

  return (
    <ModalForm
      name="configListUpload"
      withTabs={false}
      title={`Загрузка настроек из файла`}
      width={700}
      visible={visible}

      primaryField="slug"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initials}
      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default ConfigListUploadForm
