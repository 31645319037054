import { DetailValue } from 'components/common'


const CountryValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue
        linkUrl={asLink && `/country/${detail.code}/`}
        isBack={isBack}
        render={() => (
          <div className="valueFlex">
            {detail?.name || '-'}
          </div>
        )}
      />
    </>
  );
};


export default CountryValue
