import { useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { Affix, Tag } from 'antd'

import { getObjectParams, getDataQuery } from 'utils/urls'

import './styles.css'


const excludeFields = ['sort', 'page', 'search'];


const FilterValueItem = ({ filterValueItem, onRemoveFilterParam }) => {
  return (
    <div className="filterValue">
      <div className="filterValue__label">{filterValueItem.label}:</div>
      <div className="filterValue__values">
        {filterValueItem.values.map(([value, label]) => (
          <Tag
            key={`fv-${filterValueItem.name}-${value}`}
            closable
            onClose={e => onRemoveFilterParam(e, filterValueItem.name, value)}
          >
            {label}
          </Tag>
        ))}
      </div>
    </div>
  )
}

const DefaultDisplayValue = ({ value }) => {
  return value
}


const SmartFilterValues = ({ queryKey, onChange, fields, container }) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const objectSearchParams = useMemo(() => {
    return getObjectParams(location.search, queryKey);
  }, [location.search, queryKey]);

  const fieldsByName = (fields || []).reduce((res, item) => {
    res[item.fieldName] = item;
    return res;
  }, {});

  const filters = useMemo(() => {
    let fieldNames = {};
    let result = [];
    for (let fieldName of objectSearchParams.keys()) {
      if (excludeFields.includes(fieldName) || !objectSearchParams.get(fieldName)) {
        continue
      }
      const config = fieldsByName[fieldName] || {};
      if (!config) continue;
      let values = objectSearchParams.getAll(fieldName);
      if (config.component?.getValue) {
        values = config.component?.getValue(values);
        if (!Array.isArray(values)) {
          values = [values];
        }
      }

      const ValueComponent = config.component?.DisplayValue || DefaultDisplayValue;

      if (!fieldNames[fieldName]) {
        fieldNames[fieldName] = 1;
        result.push({
          name: fieldName,
          label: config.label || fieldName,
          values: values.map(v => ([v, <ValueComponent value={v} />])),
        });
      }
    }
    return result;
  }, [fieldsByName, objectSearchParams]);

  const onRemoveFilterParam = (e, fieldName, valueToRemove) => {
    e.preventDefault();

    const config = fieldsByName[fieldName] || {};

    let values = objectSearchParams.getAll(fieldName);
    if (config.component?.getValue) {
      values = config.component?.getValue(values);
      if (!Array.isArray(values)) {
        values = [values];
      }
    }

    let vals = [];
    for (const value of values) {
      if (value !== valueToRemove) {
        vals.push(value)
      }
    }

    objectSearchParams.delete(fieldName);
    if (vals.length > 0) {
      if (config.component?.getQueryParams) {
        vals = config.component?.getQueryParams(vals);
      }
      objectSearchParams.append(fieldName, vals);
    }

    const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
    onChange && onChange({ dataQuery, searchString });
  };

  if (filters.length === 0) return null;

  return (
    <Affix target={() => container} offsetTop={24}>
      {filters && filters.length > 0 &&
        <div className="smartFilterValues">
          <div className="smartFilterValues__title">Фильтры:</div>
          <div className="smartFilterValues__content">
            {filters.map(filterValueItem => (
              <FilterValueItem key={`${filterValueItem.name}`} filterValueItem={filterValueItem} onRemoveFilterParam={onRemoveFilterParam} />
            ))}
          </div>
        </div>
      }
    </Affix>
  );
};

export default SmartFilterValues;
