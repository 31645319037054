import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'

import { API_URL } from 'config'
import ConfigListApi from 'models/ConfigList/api'
import { DetailPage } from 'layout'
import { ConfigListForm, ConfigListUploadForm, ConfigListDetail } from 'models/ConfigList/components'


const ConfigListDetailPage = () => {
  const { configListSlug } = useParams();

  const detail = useSelector(state => state.configList.detail);
  const [getDetail, getDetailProps] = ConfigListApi.endpoints.configListGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = ConfigListApi.endpoints.configListDelete.useMutation();

  const [uploadFormState, setUploadFormState] = useState({});

  const onUpload = () => {
    setUploadFormState({ visible: true, objectId: configListSlug });
  }

  const onUploadFormClose = (isUpdated) => {
    setUploadFormState({});
    if (isUpdated) {
      getDetail(configListSlug);
    }
  }

  const linkUrl = `${API_URL}/api/config/${detail?.slug}/?download=1`; 

  return (
    <DetailPage
      objectId={configListSlug}
      detail={detail}
      title={`Список настроек «${configListSlug}»`}
      showBack={true}
      basePath={'/configlist/'}

      mainActions={[
        { name: 'download', type: 'default', icon: <DownloadOutlined />, label: 'Скачать', action: () => window.location = linkUrl },
        { name: 'upload', type: 'default', icon: <UploadOutlined />, label: 'Загрузить файл настроек', action: () => onUpload() }
      ]}

      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}

      render={(editFormState, onFormClose) => (
        <>
          <ConfigListDetail detail={detail} />

          <ConfigListForm onClose={onFormClose} {...editFormState} />
          <ConfigListUploadForm onClose={onUploadFormClose} {...uploadFormState} />
        </>
      )}
    />
  );
}

export default ConfigListDetailPage
