import { Select } from 'antd'

import { ListLoadComponent } from 'components/common'
import SiteApi from 'models/Site/api'


const SiteSelect = ({ filter, disabled, required, ...props }) => {
  const [getList, getListProps] = SiteApi.endpoints.siteGetItems.useLazyQuery();

  return (
    <ListLoadComponent
      getList={getList}
      getListProps={getListProps}
      filter={filter}

      render={({ items }) => {
        return (
          <Select
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите"
            showSearch={true}
            allowClear={true}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              return (
                <Select.Option key={`site${item.domain}`} value={item.domain} label={item.domain}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default SiteSelect
