import { DetailValue } from 'components/common'


const TaskValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue
        linkUrl={asLink && `/task/${detail.id}/`}
        isBack={isBack}
        render={() => (
          detail?.domain || '-'
        )}
      />
    </>
  );
};


export default TaskValue
