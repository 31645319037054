import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Select } from 'antd'

import CountryApi from 'models/Country/api'



const CountrySelect = ({ filter, disabled, required, ...props }) => {
  const items = useSelector(state => state.country.forSelectItems);

  const [getList] = CountryApi.endpoints.countryGetItemsForSelect.useLazyQuery();

  useEffect(() => {
    if (filter) {
      getList({ ...filter });
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Select
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Выберите"
      showSearch={true}
      allowClear={true}
      disabled={disabled}
      optionFilterProp="label"
      size="large"
      onChange={props.onChange}
      {...props}
    >
      {!required && <Select.Option value={''}>---</Select.Option>}
      {items.map(item => {
        return (
          <Select.Option key={`country${item.code}`} value={item.code} label={item.name}>
            {item.name}
          </Select.Option>
        )
      })}
    </Select>
  );
}

export default CountrySelect
