import { Form } from 'antd'

import TaskListSelect from '../TaskListSelect'


const TaskListSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <TaskListSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

TaskListSelectField.getValue = (values) => {
  return values;
}

TaskListSelectField.getDisplayValue = (values, config) => {
  return values
};

export default TaskListSelectField
