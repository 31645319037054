import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Select } from 'antd'

import CityApi from 'models/City/api'


const CitySelect = ({ filter, disabled, required, multiple, ...props }) => {
  const items = useSelector(state => state.city.forSelectItems);

  const [getList] = CityApi.endpoints.cityGetItemsForSelect.useLazyQuery();

  useEffect(() => {
    if (filter) {
      getList({ ...filter });
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Select
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Выберите"
      showSearch={true}
      allowClear={true}
      disabled={disabled}
      optionFilterProp="label"
      size="large"
      mode={multiple ? 'multiple' : undefined}
      onChange={props.onChange}
      {...props}
    >
      <Select.Option value={'0'}>- не определено -</Select.Option>
      {items.map(item => {
        return (
          <Select.Option key={`city${item.code}`} value={item.code} label={item.name}>
            {item.name}
          </Select.Option>
        )
      })}
    </Select>
  );
}

export default CitySelect
