import { Navigate } from "react-router-dom"

import { Top } from 'layout'

import './styles.css'


const HomeScreen = () => {
  return (
    <div>
      <Top
        title="Рабочий стол"
        basePath="/"
      />
      <Navigate to='/tasklist/' replace />
    </div>
  );
};

export default HomeScreen;
