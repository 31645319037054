import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import SiteApi from 'models/Site/api'
import { DetailPage } from 'layout'
import { SiteForm, SiteDetail } from '../../components'


const SiteDetailPage = () => {
  const { domain } = useParams();

  const detail = useSelector(state => state.site.detail);
  const [getDetail, getDetailProps] = SiteApi.endpoints.siteGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = SiteApi.endpoints.siteDelete.useMutation();

  return (
    <DetailPage
      objectId={domain}
      detail={detail}
      title={`Сайт ${domain}`}
      showBack={true}
      basePath={'/site/'}
      breadcrumbs={[
        { title: 'Сайты', path: '/site/' },
      ]}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      render={(editFormState, onFormClose) => (
        <>
          <SiteDetail detail={detail} />

          <SiteForm onClose={onFormClose} {...editFormState} />
        </>
      )}
    />
  );
}

export default SiteDetailPage
