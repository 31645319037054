import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import TaskApi from 'models/Task/api'
import MainFieldset from './MainFieldset'


const TaskForm = ({ visible, objectId, initials, onClose, taskListSlug, program }) => {
  const detailForEdit = useSelector(state => state.task.detailForEdit);
  const [getDetail, getDetailProps] = TaskApi.endpoints.taskGetForEdit.useLazyQuery();
  const [create, createProps] = TaskApi.endpoints.taskCreate.useMutation();
  const [update, updateProps] = TaskApi.endpoints.taskUpdate.useMutation();

  const onFinish = (values) => {
    console.log('values', values);
    let additionalDomains = values?.additionalDomains || '';
    if (!Array.isArray(additionalDomains)) {
      additionalDomains = (values?.additionalDomains || '').replace(/\s+/g, ' ').trim().split(',');
    }

    const numFrom = values?.numFrom;
    const sclick = values?.sclick || 0;
    const params = values?.params || '';

    const data = { ...values, additionalDomains, numFrom, sclick, params, listSlug: taskListSlug, isActive: true }
    console.log(data);

    if (objectId) {
      update(data);
    } else {
      create(data);
    }
  };

  const initialValues = {
    sort: 100,
    ...initials
  };

  return (
    <ModalForm
      name="task"
      title={`Редактирование задания`}
      width={700}
      visible={visible}

      primaryField="id"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail, category: detail.category?.id, place: detail.place?.id }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} program={program} />
    </ModalForm>
  );
}


export default TaskForm
