import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Fieldset, Descriptions, OnOff } from 'components/common'
import CountryApi from 'models/Country/api'


const CountryDetail = ({ detail }) => {
  const [itemUpdate, itemUpdateProps] = CountryApi.endpoints.countryUpdate.useMutation();
  const [getDetail] = CountryApi.endpoints.countryGetDetail.useLazyQuery();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getDetail(detail?.id);
    }
    // eslint-disable-next-line
  }, [detail?.id, itemUpdateProps.isSuccess]);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={21}>{detail?.name}</Descriptions.Item>

            <Descriptions.Item label="Код">{detail?.code}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} fieldName="isActive" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default CountryDetail
