import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { API_URL } from 'config'
import { Fieldset, Descriptions, DateTimeValue, OnOff, PropertyList } from 'components/common'
import WalkSiteListApi from 'models/WalkSiteList/api'

const FILTERS_LABELS = {
  'cityCode__in': 'Город:',
  'traffic__gt': 'Посещяемость >:',
  'ym__isnotnull': 'Яндекс Метрика:',
}


const WalkSiteListDetail = ({ detail }) => {
  const [itemUpdate, itemUpdateProps] = WalkSiteListApi.endpoints.walkSiteListUpdate.useMutation();
  const [getDetail] = WalkSiteListApi.endpoints.walkSiteListGetDetail.useLazyQuery();

  const categoryBySlug = useSelector(state => state.category.bySlug);
  const cityByCode = useSelector(state => state.city.byCode);

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getDetail(detail?.slug);
    }
    // eslint-disable-next-line
  }, [detail?.id, itemUpdateProps.isSuccess]);

  const linkUrl = `${API_URL}/api/walksitelist/${detail?.slug}/`;

  const categories = Object.keys(detail?.categories || {}).map(slug => {
    const name = categoryBySlug[slug]?.name || slug;
    const percent = detail?.categories[slug];
    return { label: `${name}:`, value: percent }
  });

  const filters = Object.keys(detail?.filters || {}).map(key => {
    //const name = categoryBySlug[slug]?.name || slug;
    const label = FILTERS_LABELS[key] || `${key}:`;
    let value = detail?.filters[key];
    if (key === 'cityCode__in') {
      value = value.map(code => cityByCode[code]?.name || code).join(', ');
    }
    return { label, value }
  });

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Название" md={12}>{detail?.name}</Descriptions.Item>

            <Descriptions.Item label="Ссылка на список заданий" md={24}>
              <span>{linkUrl}</span>
              <Button size='small' style={{ marginLeft: '8px' }} onClick={() => { navigator.clipboard.writeText(linkUrl) }}>
                <CopyOutlined />
              </Button>
            </Descriptions.Item>

            <Descriptions.Item label="Категории" md={24}>
              <PropertyList items={categories} />
            </Descriptions.Item>

            <Descriptions.Item label="Фильтры" md={24}>
              <PropertyList items={filters} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} primaryField="slug" fieldName="isActive" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.createdAt} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default WalkSiteListDetail
