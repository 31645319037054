import { Form } from 'antd'

import SiteSelect from '../SiteSelect'


const SiteSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <SiteSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

SiteSelectField.getValue = (values) => {
  return values;
}

SiteSelectField.getDisplayValue = (values, config) => {
  return values
};

export default SiteSelectField
