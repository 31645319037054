import { Form } from 'antd'

import ConfigListSelect from '../ConfigListSelect'


const ConfigListSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ConfigListSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

ConfigListSelectField.getValue = (values) => {
  return values;
}

ConfigListSelectField.getDisplayValue = (values, config) => {
  return values
};

export default ConfigListSelectField
