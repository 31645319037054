import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Skeleton } from 'antd'

import { Page } from 'layout'


const DetailPage = ({
  objectId,
  basePath,
  title,
  showBack,
  tabs,
  getDetail,
  getDetailProps,
  mainActions,
  otherActions,
  itemDelete,
  itemDeleteProps,
  render
}) => {
  const navigate = useNavigate();

  const [editFormState, setEditFormState] = useState({});

  useEffect(() => {
    getDetail(objectId);
    // eslint-disable-next-line
  }, [objectId]);

  useEffect(() => {
    if (itemDeleteProps.isSuccess) {
      navigate(basePath)
    }
    // eslint-disable-next-line
  }, [itemDeleteProps.isSuccess]);

  useEffect(() => {
    if (getDetailProps.isError) {
      navigate('/404/', { replace: true })
    }
    // eslint-disable-next-line
  }, [getDetailProps.isError, getDetailProps.error?.status]);

  const onEdit = () => {
    setEditFormState({ visible: true, objectId });
  }

  const onDelete = () => {
    itemDelete(objectId)
  }

  const onFormClose = (isUpdated) => {
    setEditFormState({});
    isUpdated && getDetail(objectId);
  }

  let _mainActions = mainActions;
  if (mainActions === false) {
    _mainActions = null;
  } else if (!mainActions) {
    _mainActions = [{ name: 'edit', action: () => onEdit() }];
  }

  let _otherActions = otherActions;
  if (otherActions === false) {
    _otherActions = null;
  } else if (!otherActions) {
    _otherActions = [{ name: 'edit', action: () => onEdit() }, { name: 'delete', action: () => onDelete() }];
  } else {
    _otherActions = [{ name: 'edit', action: () => onEdit() }, ...otherActions, { name: 'delete', action: () => onDelete() }];
  }

  return (
    <Page
      title={title}
      showBack={showBack}
      mainActions={_mainActions}
      otherActions={_otherActions}
      basePath={basePath}
      tabs={tabs}
    >
      {getDetailProps.isLoading ?
        <Skeleton /> :
        render(editFormState, onFormClose)
      }
    </Page>
  );
}

export default DetailPage
