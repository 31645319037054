import { api } from 'redux/api/api'


const SiteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    siteGetItems: builder.query({
      query: (params = null) => ({
        url: 'site/',
        params: params,
      }),
    }),
    siteGetDetail: builder.query({
      query: (id) => ({
        url: `site/${id}/`,
      }),
    }),
    siteGetForEdit: builder.query({
      query: (id) => ({
        url: `site/${id}/`,
      }),
    }),

    siteCreate: builder.mutation({
      query: (values) => ({
        url: 'site/',
        method: 'POST',
        body: values,
      }),
    }),
    siteUpdate: builder.mutation({
      query: (values) => ({
        url: `site/${values.domain}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    siteDelete: builder.mutation({
      query: (id) => ({
        url: `site/${id}/`,
        method: 'DELETE',
      }),
    }),
    siteBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'site/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),
  }),
});

export default SiteApi;
