import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Menu } from 'antd'
import { ClusterOutlined, GroupOutlined, EnvironmentOutlined, BarsOutlined, GlobalOutlined } from '@ant-design/icons'

import './styles.css';


function getItem(label, key, icon, type, children) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  //getItem('Рабочий стол', '/', <DashboardOutlined />),

  getItem('Задания', 'task-group', null, 'group'),

  getItem('Списки заданий', '/tasklist/', <ClusterOutlined />),
  getItem('Сайты', '/site/', <GlobalOutlined />),

  { type: 'divider' },

  getItem('Ссылки для нагула', 'walk-group', null, 'group'),

  getItem('Страны', '/country/', <EnvironmentOutlined />),
  getItem('Категории', '/category/', <BarsOutlined />),
  getItem('Сайты для нагула', '/walksite/', <GlobalOutlined />),
  getItem('Списки для нагула', '/walksitelist/', <GlobalOutlined />),

  { type: 'divider' },

  getItem('Настройки', 'config-group', null, 'group'),

  getItem('Списки настроек', '/configlist/', <GroupOutlined />),
];


const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedKeys = useMemo(() => {
    return location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
      const before = result.length > 0 ? result[result.length - 1] : '/';
      result.push(`${before}${slug}/`);
      return result
    }, []);
  }, [location.pathname])

  const onNavigate = ({ key }) => {
    navigate(key);
  }

  return (
    <Menu
      className="navigation"
      inlineIndent={16}
      mode="inline"
      theme="dark"
      items={items}
      defaultOpenKeys={selectedKeys}
      selectedKeys={selectedKeys}
      onClick={onNavigate}
    />
  );
}

export default Navigation;
