import { useState, useRef } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { ConfigListTable, ConfigListForm } from '../../components'

import './styles.css'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const ConfigListMainPage = () => {
  const [pageContainer, setPageContainer] = useState(null);
  const tableRef = useRef(null);

  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <Page
      title={`Списки настроек`}
      mainActions={[
        {name: 'add', type: 'primary', action: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="configList" onChange={onFilterChange} fields={filterFields} />}
      contentContainer={setPageContainer}
    >
      <SmartFilterValues queryKey="configList" onChange={onFilterChange} fields={filterFields} container={pageContainer} />

      <ConfigListTable onEdit={onEdit} ref={tableRef} />

      <ConfigListForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && tableRef.current.loadData({});
        }}
        {...editFormState}
      />
    </Page>
  )
}

export default ConfigListMainPage
