import { useState, useRef } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { SiteTable, SiteForm } from 'models/Site/components'

import './styles.css'


const SiteMainPage = () => {
  const [pageContainer, setPageContainer] = useState(null);
  const tableRef = useRef(null);

  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <Page
      title={`Сайты`}
      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="site" onChange={onFilterChange} />}
      contentContainer={setPageContainer}
    >
      <SmartFilterValues queryKey="site" container={pageContainer} onChange={onFilterChange} />

      <SiteTable onEdit={onEdit} ref={tableRef} />

      <SiteForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && tableRef.current.loadData({});
        }}
        {...editFormState}
      />
    </Page>
  )
}

export default SiteMainPage
