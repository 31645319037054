import { EditOutlined } from "@ant-design/icons"

import { numberWithCommas } from 'utils/format'
import { ActionButton, OnOff, BoolValue, DateTimeValue } from 'components/common'


export function getColumnActions({ onEdit, onDelete, primaryField = 'id', ...props }) {
  return {
    align: 'right',
    width: 115,
    ...props,
    render: (val, item) => (
      <ActionButton
        title="Ред."
        icon={<EditOutlined />}
        onClick={() => onEdit(item[primaryField], item)}
        actions={[
          { name: 'edit', action: () => onEdit(item[primaryField], item) },
          { name: 'delete', action: () => onDelete(item[primaryField]) }
        ]}
      />
    ),
  }
}


export function getColumnOnOff({ title, fieldName, itemUpdate, itemUpdateProps, primaryField, ...props }) {
  return {
    title: title,
    width: 86,
    ...props,
    render: (val, item) => (<OnOff detail={item} fieldName={fieldName} primaryField={primaryField} itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />),
  }
}


export function getColumnBool({ title, fieldName, ...props }) {
  return {
    title: title,
    width: 40,
    ...props,
    render: (val, item) => (<BoolValue value={item[fieldName]} />),
  }
}


export function getColumnDateTime({ title, fieldName, format, size, ...props }) {
  return {
    title: title,
    width: 110,
    ...props,
    render: (val, item) => (<DateTimeValue date={val} format={format} size={size} />),
  }
}

export function getColumnNumber({ title, fieldName, fixed = null, ...props }) {
  return {
    title: title,
    width: 150,
    ...props,
    render: (val, item) => numberWithCommas(item[fieldName], fixed),
  }
}
