import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import CountryApi from 'models/Country/api'

import MainFieldset from './MainFieldset'


const CountryForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.country.detailForEdit);
  const [getDetail, getDetailProps] = CountryApi.endpoints.countryGetForEdit.useLazyQuery();
  const [create, createProps] = CountryApi.endpoints.countryCreate.useMutation();
  const [update, updateProps] = CountryApi.endpoints.countryUpdate.useMutation();

  const onFinish = (values) => {
    console.log(values);
    if (objectId) {
      update(values);
    } else {
      create(values);
    }
  };

  const initialValues = {
    ...initials
  };

  return (
    <ModalForm
      name="country"
      withTabs={false}
      title={`Редактирование страны ${objectId || ''}`}
      width={700}
      visible={visible}

      primaryField="code"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default CountryForm
