import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  forSelectItems: [],

  detail: null,
  detailForEdit: null,
};

export const walkSiteListSlice = createSlice({
  initialState,
  name: 'walkSiteList',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.walkSiteListGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.walkSiteListGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.walkSiteListGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.walkSiteListGetItemsForSelect.matchFulfilled, (state, action) => {
        const items = action.payload || [];
        state.forSelectItems = items;
      })

      .addMatcher(api.endpoints.walkSiteListGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.walkSiteListGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.walkSiteListUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default walkSiteListSlice.reducer;
