import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal, Form, Button, Spin } from 'antd'

import FormError from './FormError'

import "./styles.css"


/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '«${label}» обязательное поле',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const ModalForm = ({
  visible,
  objectId,
  primaryField = 'id',
  detailForEdit,
  getDetail,
  getDetailProps,
  createProps,
  updateProps,
  initialValues,
  onFinish,
  ...props
}) => {
  const [form] = Form.useForm();

  const detailId = detailForEdit && detailForEdit[primaryField || 'id'];

  useEffect(() => {
    if (objectId && objectId !== detailId && !getDetailProps?.isLoading && !getDetailProps?.isError) {
      getDetail && getDetail(objectId);
    }
  }, [detailId, objectId, getDetailProps?.isLoading, getDetailProps?.isError, getDetail]);

  useEffect(() => {
    if (visible) {
      form.resetFields();

      if (objectId === detailId) {
        const values = props.prepareFormValues ? props.prepareFormValues(detailForEdit) : { ...detailForEdit };
        form.setFieldsValue(values);
      }
    }
    // eslint-disable-next-line
  }, [visible, detailForEdit]);

  const changeProps = objectId ? updateProps : createProps;
  const isSuccess = changeProps?.isSuccess;
  const isError = changeProps?.isError;
  const error = changeProps?.error;
  const errorData = error?.data || {};
  const isLoading = getDetailProps?.isLoading || changeProps?.isLoading || false;

  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (saved && isSuccess) {
      props.onClose(true, changeProps);
      setSaved(false);
    }
    // eslint-disable-next-line
  }, [saved, isSuccess]);

  useEffect(() => {
    if (isError) {
      const fields = Object.keys(errorData).map(fieldName => {
        return { name: fieldName, errors: errorData[fieldName] }
      })
      form.setFields(fields);
      setSaved(false);
    }
    // eslint-disable-next-line
  }, [isError]);

  return (
    <Modal
      className="modalForm"
      width={props.width || 600}
      open={visible}
      onCancel={props.onClose}
      maskClosable={false}
      destroyOnClose={true}
      forceRender={true}
      footer={[
        <Button key="cancel" onClick={props.onClose} disabled={isLoading}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={() => form.submit()}>
          Сохранить
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Form
          name={props.name}
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={values => {
            let data = { ...values };
            if (objectId && !data[primaryField]) {
              data[primaryField] = objectId;
            }
            setSaved(true);
            onFinish && onFinish(data)
          }}
          onFinishFailed={props.onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <div className={`modalFormHeader ${props.withTabs && "modalFormHeader__withTabs"}`}>
            <div className="modalFormTitle">{props.title}</div>
          </div>
          <FormError error={error} />
          {props.children}
        </Form>
      </Spin>
    </Modal>
  );
};

ModalForm.Error = FormError;

export default ModalForm;
