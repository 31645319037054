import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import CountryApi from 'models/Country/api'
import CountryValue from '../CountryValue'


const defaultColumnList = ['name', 'code', 'isActive', 'actions'];


const CountryTable = forwardRef(({ columnList, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.country.items);
  const pagination = useSelector(state => state.country.pagination);

  const [getList, getListProps] = CountryApi.endpoints.countryGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = CountryApi.endpoints.countryUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = CountryApi.endpoints.countryDelete.useMutation();
  const [batchDelete, batchDeleteProps] = CountryApi.endpoints.countryBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => <CountryValue detail={item} asLink={true} isBack={true} />,
    },
    code: {
      title: 'Код',
      sorter: true,
      width: 150,
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "code", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "code" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="country"
      rowKey="code"

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
    />
  );
});

export default CountryTable;
