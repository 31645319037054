import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { DownloadOutlined } from '@ant-design/icons'

import { DetailPage } from 'layout'
import { Fieldset, SmartFilter, SmartFilterValues } from 'components/common'

import { API_URL } from 'config'
import WalkSiteListApi from 'models/WalkSiteList/api'
import { WalkSiteListForm, WalkSiteListDetail } from 'models/WalkSiteList/components'
import { WalkSiteTable } from 'models/WalkSite/components'


const walkSiteFilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const WalkSiteListDetailPage = () => {
  const walkSiteTableRef = useRef(null);
  const { walkSiteListSlug } = useParams();

  const detail = useSelector(state => state.walkSiteList.detail);
  const [getDetail, getDetailProps] = WalkSiteListApi.endpoints.walkSiteListGetDetail.useLazyQuery();
  const [itemDelete, itemDeleteProps] = WalkSiteListApi.endpoints.walkSiteListDelete.useMutation();

  const onFilterChange = ({ dataQuery, searchString }) => {
    walkSiteTableRef.current.loadData({ dataQuery, searchString });
  }

  const linkUrl = `${API_URL}/api/walksitelist/${detail?.slug}/?download=1`;

  const categories = Object.keys(detail?.categories || {}).reduce((acc, slug) => {
    const percent = detail?.categories[slug];
    if (percent) {
      acc.push(slug);
    }
    return acc
  }, []);

  const filters = Object.keys(detail?.filters || {}).reduce((acc, key) => {
    const value = detail?.filters[key];
    if (value) {
      if (Array.isArray(value) && value.length === 0) return acc;
      acc[key] = value;
    }
    return acc
  }, {});

  const walkSiteColumnList = ['domain', 'geo', 'categories', 'traffic', 'ipv6', 'syncedAt', 'isActive'];

  let siteFilters = { 
    ...filters, 
    links__notemptydict: true, 
  };

  if (categories.length > 0) {
    siteFilters.categories__overlap = categories;
  }

  return (
    <DetailPage
      objectId={walkSiteListSlug}
      detail={detail}
      title={`Список для нагула «${walkSiteListSlug}»`}
      showBack={true}
      basePath={'/tasklist/'}

      mainActions={[
        { name: 'download', type: 'default', icon: <DownloadOutlined />, label: 'Скачать', action: () => window.location = linkUrl },
      ]}

      getDetail={getDetail}
      getDetailProps={getDetailProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}

      render={(editFormState, onFormClose) => (
        <>
          <WalkSiteListDetail detail={detail} />

          <WalkSiteListForm onClose={onFormClose} {...editFormState} />

          {detail?.slug &&
            <Fieldset
              title="Сайты"
              middle={<SmartFilter queryKey="walkSite" onChange={onFilterChange} fields={walkSiteFilterFields} />}
            >
              <SmartFilterValues queryKey="walkSite" onChange={onFilterChange} fields={walkSiteFilterFields} />

              <WalkSiteTable
                ref={walkSiteTableRef}
                filterParams={siteFilters}
                columnList={walkSiteColumnList}
                //onEdit={onWalkSiteEdit}
              />
            </Fieldset>
          }
        </>
      )}
    />
  );
}

export default WalkSiteListDetailPage
