import classNames from 'classnames'

import "./styles.css"


const Fieldset = ({ className, title, children, middle, end }) => {
  const withHeader = !!title || !!middle || !!end;

  const cx = classNames(["fieldset", withHeader && "fieldset_withHeader", className]);

  return (
    <div className={cx}>
      {withHeader && (
        <div className="fieldsetHeader">
          <div className="fieldsetHeader__start">
            {title && <div className="fieldsetHeader__title">{title}</div>}
          </div>
          {middle && <div className="fieldsetHeader__middle">{middle}</div>}
          {end && <div className="fieldsetHeader__end">{end}</div>}
        </div>
      )}

      <div className="fieldsetContent">{children}</div>
    </div>
  );
};

export default Fieldset;
