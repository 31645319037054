import { useState, useRef } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { WalkSiteTable, WalkSiteForm } from 'models/WalkSite/components'
import { CountrySelectField } from 'models/Country/components'
import { CitySelectField } from 'models/City/components'
import { CategorySelectField } from 'models/Category/components'

import './styles.css'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('ipv6', 'IPV6', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('ym__isnotnull', 'Яндекс.Метрика', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('ga__isnotnull', 'Google Analytics', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('traffic__gt', 'Посещяемость >', SmartFilter.FilterNumber),
  SmartFilter.getFieldConfig('countryCode', 'Страна', CountrySelectField),
  SmartFilter.getFieldConfig('cityCode__in', 'Город', CitySelectField, { multiple: true }),
  SmartFilter.getFieldConfig('categories__overlap', 'Категории', CategorySelectField, { multiple: true }),
  SmartFilter.getFieldConfig('links__notemptydict', 'Есть ссылки', SmartFilter.FilterBool),
];


const WalkSiteMainPage = () => {
  const [pageContainer, setPageContainer] = useState(null);
  const tableRef = useRef(null);

  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <Page
      title={`Сайты для нагула`}
      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="walkSite" onChange={onFilterChange} fields={filterFields} />}
      contentContainer={setPageContainer}
    >
      <SmartFilterValues queryKey="walkSite" onChange={onFilterChange} fields={filterFields} container={pageContainer} />

      <WalkSiteTable onEdit={onEdit} ref={tableRef} />

      <WalkSiteForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && tableRef.current.loadData({});
        }}
        {...editFormState}
      />
    </Page>
  )
}

export default WalkSiteMainPage
