import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import { appInit } from 'redux/features/appSlice'
import { AppLayout } from 'layout'
import LoginPage from 'pages/LoginPage'
import HomePage from 'pages/HomePage'
import Page404 from 'pages/Page404'

import { SiteMainPage, SiteDetailPage } from 'models/Site/pages'
import { TaskListMainPage, TaskListDetailPage } from 'models/TaskList/pages'
import { ConfigListMainPage, ConfigListDetailPage } from 'models/ConfigList/pages'
import { CountryMainPage, CountryDetailPage } from 'models/Country/pages'
import { CategoryMainPage } from 'models/Category/pages'
import { WalkSiteMainPage, WalkSiteDetailPage } from 'models/WalkSite/pages'
import { WalkSiteListMainPage, WalkSiteListDetailPage } from 'models/WalkSiteList/pages'


const ProtectedRoute = () => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : token && user ? (
    <Navigate to='/' replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomePage />} />

          <Route path="/tasklist/">
            <Route path='/tasklist/' element={<TaskListMainPage />} />
            <Route path='/tasklist/:taskListSlug/' element={<TaskListDetailPage />} />
          </Route>

          <Route path="/site/">
            <Route index element={<SiteMainPage />} />
            <Route path=':domain/' element={<SiteDetailPage />} />
          </Route>

          <Route path="/configlist/">
            <Route path='/configlist/' element={<ConfigListMainPage />} />
            <Route path='/configlist/:configListSlug/' element={<ConfigListDetailPage />} />
          </Route>

          <Route path="/country/">
            <Route index element={<CountryMainPage />} />
            <Route path=':countryCode/' element={<CountryDetailPage />} />
          </Route>

          <Route path="/category/">
            <Route index element={<CategoryMainPage />} />
          </Route>

          <Route path="/walksite/">
            <Route index element={<WalkSiteMainPage />} />
            <Route path=':walkSiteDomain/' element={<WalkSiteDetailPage />} />
          </Route>

          <Route path="/walksitelist/">
            <Route index element={<WalkSiteListMainPage />} />
            <Route path=':walkSiteListSlug/' element={<WalkSiteListDetailPage />} />
          </Route>
        </Route>
      </Route>

      <Route path='/login/' element={<LoginPage />} />

      <Route path='*' element={<Page404 />} />
    </Routes>
  );
}

export default App;
