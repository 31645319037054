import React from 'react'
import { Row, Col } from 'antd'
import classNames from "classnames"

import './styles.css'


const DescriptionsItem = ({ className, size, defaultSpan, span, xs, md, gutter, label, children }) => {
  const cx = classNames([
    "descriptionsItem",
    size && `descriptionsItem--${size}`,
    className,
  ]);

  const cxContent = classNames([
    "descriptionsItemContent",
    (children === undefined || children === null || children.length === 0) && "descriptionsItemContent--empty",
  ]);

  return (
    <Col className={cx} span={span || defaultSpan} gutter={gutter} xs={xs} md={md}>
      <div className="descriptionsItemWrap">
        {label && <div className="descriptionsItemLabel">{label}</div>}
        <div className={cxContent}>{children}</div>
      </div>
    </Col>
  );
};

const Descriptions = ({ className, gutter, children }) => {
  const cx = classNames([
    "descriptions",
    className,
  ]);

  const _gutter = gutter || [16, 24];

  const childrenWithExtraProp = React.Children.map(children, child => {
    return child && React.cloneElement(child, {
      defaultSpan: 24,
      _gutter
    });
  });

  return (
    <Row className={cx} type="flex" gutter={_gutter}>
      {childrenWithExtraProp}
    </Row>
  );
};

Descriptions.Item = DescriptionsItem;

export default Descriptions;
