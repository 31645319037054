import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  forSelectItems: [],

  detail: null,
  detailForEdit: null,
};

export const walkSiteSlice = createSlice({
  initialState,
  name: 'walkSite',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.walkSiteGetItems.matchPending, (state, action) => {
        //state.items = [];
      })
      .addMatcher(api.endpoints.walkSiteGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.walkSiteGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.walkSiteGetItemsForSelect.matchFulfilled, (state, action) => {
        const items = action.payload || [];
        state.forSelectItems = items;
      })

      .addMatcher(api.endpoints.walkSiteGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.walkSiteGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.walkSiteUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default walkSiteSlice.reducer;
