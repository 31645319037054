import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, DateTimeValue, TableColumns } from 'components/common'
import WalkSiteListApi from 'models/WalkSiteList/api'
import WalkSiteListValue from '../WalkSiteListValue'


const defaultColumnList = ['name', 'program', 'taskCount', 'createdAt', 'isActive', 'actions'];


const WalkSiteListList = forwardRef(({ columnList, topActions, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.walkSiteList.items);
  const pagination = useSelector(state => state.walkSiteList.pagination);

  const [getList, getListProps] = WalkSiteListApi.endpoints.walkSiteListGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = WalkSiteListApi.endpoints.walkSiteListUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = WalkSiteListApi.endpoints.walkSiteListDelete.useMutation();
  const [batchDelete, batchDeleteProps] = WalkSiteListApi.endpoints.walkSiteListBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => <WalkSiteListValue detail={item} asLink={true} isBack={true} />,
    },
    program: {
      title: 'Программа',
      sorter: true,
    },
    taskCount: {
      title: 'Кол-во заданий',
      sorter: false,
      width: 120,
    },
    createdAt: {
      title: 'Создано',
      sorter: true,
      width: 120,
      render: (val, item) => <DateTimeValue date={val} />,
    },

    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "slug", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "slug" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="walkSiteList"
      rowKey="name"

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
      hideStat={false}
    />
  );
});

export default WalkSiteListList;
