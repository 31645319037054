import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  byCode: {},
  forSelectItems: [],

  detail: null,
  detailForEdit: null,
};

export const countrySlice = createSlice({
  initialState,
  name: 'country',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.countryGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.countryGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.countryGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.countryGetItemsForSelect.matchFulfilled, (state, action) => {
        const items = action.payload || [];
        state.forSelectItems = items;
        state.byCode = items.reduce((acc, item) => {
          acc[item.code] = item;
          return acc
        }, {})
      })

      .addMatcher(api.endpoints.countryGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.countryGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.countryUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default countrySlice.reducer;
