import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, TableColumns, DateTimeValue } from 'components/common'
import ConfigListApi from 'models/ConfigList/api'
import ConfigListValue from '../ConfigListValue'


const defaultColumnList = ['name', 'contentLines', 'createdAt', 'isActive', 'actions'];


const ConfigListList = forwardRef(({ columnList, topActions, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.configList.items);
  const pagination = useSelector(state => state.configList.pagination);

  const [getList, getListProps] = ConfigListApi.endpoints.configListGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = ConfigListApi.endpoints.configListUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = ConfigListApi.endpoints.configListDelete.useMutation();
  const [batchDelete, batchDeleteProps] = ConfigListApi.endpoints.configListBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => <ConfigListValue detail={item} asLink={true} isBack={true} />,
    },
    contentLines: { title: 'Кол-во строк', sorter: true, width: 120 },
    createdAt: {
      title: 'Создано',
      sorter: true,
      width: 120,
      render: (val, item) => <DateTimeValue date={val} />,
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "slug", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "slug" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="configList"
      rowKey="slug"

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
    />
  );
});

export default ConfigListList;
