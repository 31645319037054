import { useEffect } from 'react'
import { Row, Col, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { API_URL } from 'config'
import { Fieldset, Descriptions, DateTimeValue, OnOff } from 'components/common'
import TaskListApi from 'models/TaskList/api'


const TaskListDetail = ({ detail }) => {
  const [itemUpdate, itemUpdateProps] = TaskListApi.endpoints.taskListUpdate.useMutation();
  const [getDetail] = TaskListApi.endpoints.taskListGetDetail.useLazyQuery();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getDetail(detail?.slug);
    }
    // eslint-disable-next-line
  }, [detail?.id, itemUpdateProps.isSuccess]);

  const linkUrl = `${API_URL}/api/task/${detail?.slug}/`;

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Название" md={12}>{detail?.name}</Descriptions.Item>

            <Descriptions.Item label="Программа" md={12}>{detail?.program}</Descriptions.Item>

            <Descriptions.Item label="Ссылка на список заданий" md={24}>
              <span>{linkUrl}</span>
              <Button size='small' style={{ marginLeft: '8px' }} onClick={() => { navigator.clipboard.writeText(linkUrl) }}>
                <CopyOutlined />
              </Button>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} primaryField="slug" fieldName="isActive" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания">
              <DateTimeValue date={detail?.createdAt} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default TaskListDetail
