import { DetailValue } from 'components/common'


const WalkSiteValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue
        linkUrl={asLink && `/walksite/${detail.domain}/`}
        isBack={isBack}
        render={() => (
          <div className="valueFlex">
            {detail?.domain || '-'}
          </div>
        )}
      />
    </>
  );
};


export default WalkSiteValue
