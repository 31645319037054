import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import WalkSiteApi from 'models/WalkSite/api'
import MainFieldset from './MainFieldset'


const WalkSiteLinkForm = ({ visible, objectId, initials, onClose, domain, walkSite, link }) => {
  const detailForEdit = useSelector(state => state.walkSite.detailForEdit);
  const [update, updateProps] = WalkSiteApi.endpoints.walkSiteUpdate.useMutation();

  const onFinish = (values) => {
    let url = values.url;
    if (!url.startsWith(domain)) {
      if (url.startsWith('/')) {
        const schema = walkSite?.https ? 'https' : 'http';
        url = `${schema}://${domain}${url}`;
      } else {
        return
      }
    }
    const data = {
      url: url,
      isActive: values.isActive,
      status: 200,
    }
    let _links = { ...walkSite.links };
    const currentLink = _links[data.url];
    _links[data.url] = { ...currentLink, ...data };
    if (objectId && objectId !== data.url) {
      delete _links[objectId];
    }

    console.log(_links[data.url]);

    update({ domain, links: _links });
  };

  const initialValues = {
    ...initials,
    domain: domain,
  };

  return (
    <ModalForm
      name="walkSiteLink"
      title={`Редактирование ссылки ${objectId}`}
      width={700}
      visible={visible}

      primaryField="url"
      objectId={objectId} 
      detailForEdit={link}
      //getDetail={getDetail}
      //getDetailProps={getDetailProps}
      createProps={updateProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default WalkSiteLinkForm
