import { Form } from 'antd'
import { useSelector } from 'react-redux'

import CountrySelect from '../CountrySelect'


const CountrySelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CountrySelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

CountrySelectField.getValue = (values) => {
  return values;
}

const DisplayValue = ({ value }) => {
  const byCode = useSelector(state => state.country.byCode);
  const cachedItem = byCode[value];

  return cachedItem?.name || value;
};

CountrySelectField.DisplayValue = DisplayValue;

export default CountrySelectField
