import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, TableColumns } from 'components/common'
import CityApi from 'models/City/api'
import CityValue from '../CityValue'


const defaultColumnList = ['name', 'code', 'isActive', 'actions'];


const CityTable = forwardRef(({ columnList, filterParams, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.city.items);
  const pagination = useSelector(state => state.city.pagination);

  const [getList, getListProps] = CityApi.endpoints.cityGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = CityApi.endpoints.cityUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = CityApi.endpoints.cityDelete.useMutation();
  const [batchDelete, batchDeleteProps] = CityApi.endpoints.cityBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (id) => {
    itemDelete(id)
  }

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => <CityValue detail={item} asLink={false} isBack={true} />,
    },
    code: {
      title: 'Код',
      sorter: true,
      width: 150,
    },

    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "code", itemUpdate, itemUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "code" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="city"
      rowKey="code"
      filterParams={filterParams}

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={true}
    />
  );
});

export default CityTable;
