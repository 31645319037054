import { api } from 'redux/api/api'


const TaskListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    taskListGetItems: builder.query({
      query: (params = null) => ({
        url: 'tasklist/',
        params: params,
      }),
    }),
    taskListGetDetail: builder.query({
      query: (id) => ({
        url: `tasklist/${id}/`,
      }),
    }),
    taskListGetForEdit: builder.query({
      query: (id) => ({
        url: `tasklist/${id}/`,
      }),
    }),

    taskListGetItemsForSelect: builder.query({
      query: (params = null) => ({
        url: 'tasklist/',
        params: {
          ...params,
          for_select: true,
        },
      }),
    }),

    taskListCreate: builder.mutation({
      query: (values) => ({
        url: 'tasklist/',
        method: 'POST',
        body: values,
      }),
    }),
    taskListUpdate: builder.mutation({
      query: (values) => ({
        url: `tasklist/${values.slug}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    taskListDelete: builder.mutation({
      query: (id) => ({
        url: `tasklist/${id}/`,
        method: 'DELETE',
      }),
    }),
    taskListBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'tasklist/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),

    taskListUpload: builder.mutation({
      query: ({ slug, data }) => ({
        url: `tasklist/${slug}/upload/`,
        method: 'POST',
        body: data,
        formData: true,
      }),
    }),
  }),
})


export default TaskListApi;
