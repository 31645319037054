import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { CountrySelectField } from 'models/Country/components'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  const countryCode = initials?.countryCode;

  return (
    <Row gutter={32}>
      <Col span={16}>
        <CountrySelectField label="Страна" name="countryCode" required={true} disabled={!!countryCode} />

        <TextField label="Код" name="code" required={true} maxLength={100} />

        <TextField label="Название" name="name" required={true} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
