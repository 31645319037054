import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import CategorySelectField from '../../CategorySelectField'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <CategorySelectField label="Родительская категория" name="parentSlug" required={false} />

        <TextField label="Название" name="name" required={true} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
