import { useState } from 'react'
import { Row, Col } from 'antd'

import { FormFields } from 'components/common'
import { CountrySelectField } from 'models/Country/components'
import { CitySelectField } from 'models/City/components'
import { CategorySelectField } from 'models/Category/components'

const { TextField, NumberField, SwitchField, CheckboxField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  const [countryCode, setCountryCode] = useState(null);

  return (
    <Row gutter={32}>
      <Col span={16}>
        <CountrySelectField label="Страна" name="countryCode" required={true} onChange={v => setCountryCode(v)} />

        <CitySelectField label="Город" name="cityCode" required={false} filter={{ countryCode: countryCode || detailForEdit?.countryCode }} />

        <CategorySelectField label="Категории" name="categories" multiple={true} required={false} />

        <TextField label="Домен" name="domain" required={true} />

        <TextField label="Yandex Метрика" name="ym" required={false} />
        <TextField label="Google Analytics" name="ga" required={false} />

      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <CheckboxField label="HTTPS?" name="https" />

        <CheckboxField label="IPV6?" name="ipv6" />

        <NumberField label="Посещаемость" name="traffic" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
