import { Form } from 'antd'

import WalkSiteSelect from '../WalkSiteSelect'


const WalkSiteSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <WalkSiteSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

WalkSiteSelectField.getValue = (values) => {
  return values;
}

WalkSiteSelectField.getDisplayValue = (values, config) => {
  return values
};

export default WalkSiteSelectField
