import { api } from 'redux/api/api'


const CityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    cityGetItems: builder.query({
      query: (params = null) => ({
        url: 'city/',
        params: params,
      }),
    }),
    cityGetDetail: builder.query({
      query: (code) => ({
        url: `city/${code}/`,
      }),
    }),
    cityGetForEdit: builder.query({
      query: (code) => ({
        url: `city/${code}/?forEdit=1`,
      }),
    }),

    cityGetItemsForSelect: builder.query({
      query: (params = null) => ({
        url: 'city/',
        params: {
          ...params,
          for_select: true,
        },
      }),
    }),

    cityCreate: builder.mutation({
      query: (values) => ({
        url: 'city/',
        method: 'POST',
        body: values,
      }),
    }),
    cityUpdate: builder.mutation({
      query: (values) => ({
        url: `city/${values.code}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    cityDelete: builder.mutation({
      query: (code) => ({
        url: `city/${code}/`,
        method: 'DELETE',
      }),
    }),
    cityBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'city/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),
  }),
});

export default CityApi;
