import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { Dropdown, Avatar } from 'antd'
import { LogoutOutlined, CaretDownOutlined, UserOutlined } from '@ant-design/icons'

import AuthApi from 'redux/api/authApi'
import { getFullNameOrEmail } from 'utils/helpers'

import './styles.css'


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('Выход', '/logout/', <LogoutOutlined />),
];


const UserPanel = () => {
  const user = useSelector(state => state.auth.user);
  const [logoutUser] = AuthApi.endpoints.logoutUser.useMutation();
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    console.log('key', key)
    if (key === '/logout/') {
      logoutUser();
    } else {
      navigate(key);
    }
  };

  const name = getFullNameOrEmail(user);

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }} overlayClassName="userPanelDropdown" trigger="click" arrow={false} placement="bottom">
      <div className="userPanel">
        <Avatar className="userPanel__avatar" icon={<UserOutlined />} src={user.photo} />
        <span className="userPanel__name">{name}</span>
        <CaretDownOutlined className="userPanel__icon" />
      </div>
    </Dropdown>
  );
};

export default UserPanel;
