import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { TableList, TableColumns, DateTimeValue } from 'components/common'
import SiteApi from 'models/Site/api'
import SiteValue from '../SiteValue'


const defaultColumnList = ['domain', 'http_schema', 'createdAt'];


const SiteTable = forwardRef(({ columnList, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.site.items);
  const pagination = useSelector(state => state.site.pagination);

  const [getList, getListProps] = SiteApi.endpoints.siteGetItems.useLazyQuery();
  const [itemUpdate, itemUpdateProps] = SiteApi.endpoints.siteUpdate.useMutation();
  const [itemDelete, itemDeleteProps] = SiteApi.endpoints.siteDelete.useMutation();
  const [batchDelete, batchDeleteProps] = SiteApi.endpoints.siteBatchDelete.useMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const onDelete = (detail) => {
    itemDelete(detail.id)
  }

  const fields = {
    domain: {
      title: 'Домен',
      sorter: true,
      render: (val, item) => <SiteValue detail={item} asLink={false} isBack={true} />,
    },
    http_schema: {
      title: 'Схема',
      sorter: true,
      width: 150,
    },
    createdAt: {
      title: 'Создано',
      sorter: true,
      width: 120,
      render: (val, item) => <DateTimeValue date={val} />,
    },
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "domain" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      pagination={pagination}
      queryKey="site"
      rowKey="domain"

      getList={getList}
      getListProps={getListProps}
      itemUpdate={itemUpdate}
      itemUpdateProps={itemUpdateProps}
      itemDelete={itemDelete}
      itemDeleteProps={itemDeleteProps}
      batchDelete={batchDelete}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      rowSelection={false}
    />
  );
});

export default SiteTable;
