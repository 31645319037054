import { Form } from 'antd'

import TaskSelect from '../TaskSelect'


const TaskSelectField = ({ name, label, required, disabled, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <TaskSelect required={required} disabled={disabled} filter={filter} onChange={onChange} />
    </Form.Item>
  );
}

TaskSelectField.getValue = (values) => {
  return values;
}

TaskSelectField.getDisplayValue = (values, config) => {
  return values
};

export default TaskSelectField
