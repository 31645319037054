import { Button } from 'antd'

import { getNoun } from 'utils/helpers'

import './styles.css'


const TableRawActions = ({ rowActions, selected }) => {
  if (!rowActions || rowActions.length === 0) {
    return null
  }
  const count = selected.length;

  if (count === 0) {
    return null
  }

  return (
    <div className="tableRowActions">
      <div className="tableRowActions__title">{count} {getNoun(count, ['элемент', 'элемента', 'элементов'])}:</div>
      {rowActions.map((item, index) => (
        <div className="tableTopRowActions__item" key={`row-action${index}`}>
          <Button type={item.type} onClick={() => item.action(selected)} size="small">
            {item.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default TableRawActions;
