import { api } from 'redux/api/api'


const CountryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    countryGetItems: builder.query({
      query: (params = null) => ({
        url: 'country/',
        params: params,
      }),
    }),
    countryGetDetail: builder.query({
      query: (code) => ({
        url: `country/${code}/`,
      }),
    }),
    countryGetForEdit: builder.query({
      query: (code) => ({
        url: `country/${code}/?forEdit=1`,
      }),
    }),

    countryGetItemsForSelect: builder.query({
      query: (params = null) => ({
        url: 'country/',
        params: {
          ...params,
          for_select: true,
        },
      }),
    }),

    countryCreate: builder.mutation({
      query: (values) => ({
        url: 'country/',
        method: 'POST',
        body: values,
      }),
    }),
    countryUpdate: builder.mutation({
      query: (values) => ({
        url: `country/${values.code}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    countryDelete: builder.mutation({
      query: (code) => ({
        url: `country/${code}/`,
        method: 'DELETE',
      }),
    }),
    countryBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'country/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),
  }),
});

export default CountryApi;
