import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Upload, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import classNames from "classnames"

import { API_URL } from 'config'
import { arrayIsEqual, isDict } from 'utils/helpers'
import './styles.css';


const FileUpload = ({ className, data, mode, title, onChange, fileList, ...otherProps }) => {
  const [initialFileList, setInitialFileList] = useState([]);
  const [files, setFiles] = useState([]);

  const token = useSelector(state => state.auth.token);

  const memoFileList = useMemo(() => {
    let _tmpList = fileList ? JSON.parse(JSON.stringify(fileList)) : [];
    if (!Array.isArray(_tmpList)) {
      if (isDict(_tmpList)) {
        _tmpList = [_tmpList];
      } else {
        _tmpList = [];
      }
    }
    return _tmpList;
  }, [fileList]);

  if (memoFileList && !arrayIsEqual(initialFileList, memoFileList)) {
    const _files = memoFileList.map(item => {
      item.uid = item.sha1;
      return item;
    });
    setFiles(_files);
    setInitialFileList(memoFileList);
  }

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    if (mode === 'single') {
      newFileList = newFileList.slice(-1);
    }

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.uid = file.response.sha1;
        file.path = file.response.path;
        file.url = file.response.url;
        file.sha1 = file.response.sha1;
      }
      return file;
    });

    setFiles(newFileList);

    const isUploading = newFileList.reduce((isUploading, file) => {
      if (!isUploading) {
        isUploading = file.status === 'uploading'
      }
      return isUploading;
    }, false);

    if (!isUploading) {
      /*const _files = _tmpList.map(item => ({
        //uid: item.uid,
        sha1: item.sha1,
        name: item.name,
        originFileObj: item.originFileObj,
        //path: item.path,
        url: item.url,
      }));*/
      //onChange && onChange(mode === 'single' && _files.length > 0 ? [_files[0]]: _files);
      onChange && onChange(newFileList);
    }
  };

  const cx = classNames([
    "photoUpload",
    mode === 'single' && "photoUpload--full",
    otherProps.columns && `col${otherProps.columns}`,
    className
  ]);

  return (
    <div className={cx}>
      <Upload
        className={mode === 'single' ? 'ant-upload-full' : ''}
        listType1="picture-card"
        fileList={files}
        action={`${API_URL}/api/admin/v1/files/file/`}
        data={data}
        headers={{ 'Authorization': `JWT ${token}` }}
        {...otherProps}
        onChange={handleChange}
      >
        <Button icon={<UploadOutlined />}>{title}</Button>
      </Upload>
    </div>
  );
};

export default FileUpload;
