import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  detail: null,
  detailForEdit: null,
};

export const configListSlice = createSlice({
  initialState,
  name: 'configList',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.configListGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.configListGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.configListGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.configListGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.configListGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.configListUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default configListSlice.reducer;
