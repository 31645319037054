import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


import { API_URL } from 'config'
import UploadAdapter from './uploadAdapter'


const UPLOAD_FILE_URL = `${API_URL}/api/admin/v1/files/file/`;


const HtmlEditor = ({ className, value, onChange, tags, ...otherProps }) => {
  const authToken = useSelector(state => state.auth.token);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    onChange && onChange(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      //plugins={[ImageResizeEditing, ImageResizeHandles]}
      data={value}
      onChange={handleChange}
      onReady={editor => {
        if (editor) {
          editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );

          editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader, authToken, tags);
          };
        }
      }}
      config={{
        //plugins: [ Paragraph, Bold, Italic, Essentials ],
        //plugins: [SourceEditing, Essentials, UploadAdapterPlugin, Heading, Bold, Italic],
        toolbar: {
          items: [
            'heading', 'bold', 'italic', '|',
            'bulletedList', 'numberedList', 'blockQuote', '|',
            'insertTable', '|', 'link', '|', 'imageUpload', '|',
            'undo', 'redo'//, '|', 'sourceEditing'
          ],
          shouldNotGroupWhenFull: true,
        },
        ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: UPLOAD_FILE_URL,
        }
      }}
      {...otherProps}
    />
  );
};

const HtmlField = ({ name, label, required, tags }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <HtmlEditor tags={tags} />
    </Form.Item>
  );
};


export default HtmlField;
