import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import TaskListApi from 'models/TaskList/api'
import MainFieldset from './MainFieldset'


const TaskListUploadForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.taskList.detailForEdit);
  const [getDetail, getDetailProps] = TaskListApi.endpoints.taskListGetForEdit.useLazyQuery();
  const [update, updateProps] = TaskListApi.endpoints.taskListUpload.useMutation();

  const onFinish = (values) => {
    const data = new FormData();
    data.append("file", values.file[0].originFileObj);
    update({ slug: objectId, data });
  };

  return (
    <ModalForm
      name="taskListUpload"
      withTabs={false}
      title={`Загрузка заданий из файла`}
      width={700}
      visible={visible}

      primaryField="slug"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initials}
      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default TaskListUploadForm
