import { Form, Checkbox } from 'antd'

import "./styles.css"


const CheckboxField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]} valuePropName="checked">
      <Checkbox size="large" disabled={disabled} />
    </Form.Item>
  );
};

export default CheckboxField;
