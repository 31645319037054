import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import WalkSiteApi from 'models/WalkSite/api'

import MainFieldset from './MainFieldset'


const WalkSiteForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.walkSite.detailForEdit);
  const [getDetail, getDetailProps] = WalkSiteApi.endpoints.walkSiteGetForEdit.useLazyQuery();
  const [create, createProps] = WalkSiteApi.endpoints.walkSiteCreate.useMutation();
  const [update, updateProps] = WalkSiteApi.endpoints.walkSiteUpdate.useMutation();

  const onFinish = (values) => {
    console.log(values);
    if (objectId) {
      update(values);
    } else {
      create(values);
    }
  };

  const initialValues = {
    ...initials
  };

  return (
    <ModalForm
      name="walkSite"
      withTabs={false}
      title={`Редактирование сайта ${objectId || ''}`}
      width={700}
      visible={visible}

      primaryField="domain"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail, categories: detail?.categories || [] }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default WalkSiteForm
