import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import SiteApi from 'models/Site/api'
import MainFieldset from './MainFieldset'


const SiteForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.site.detailForEdit);
  const [getDetail, getDetailProps] = SiteApi.endpoints.siteGetForEdit.useLazyQuery();
  const [create, createProps] = SiteApi.endpoints.siteCreate.useMutation();
  const [update, updateProps] = SiteApi.endpoints.siteUpdate.useMutation();

  const onFinish = (values) => {
    console.log(values);
    if (objectId) {
      update(values);
    } else {
      create(values);
    }
  };

  const initialValues = {
    http_schema: 'https',
    ...initials
  };

  return (
    <ModalForm
      name="site"
      withTabs={false}
      title={`Редактирование сайта ${objectId || ''}`}
      width={700}
      visible={visible}
      objectId={objectId}

      primaryField="domain"
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default SiteForm
