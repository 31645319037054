import { useState, useRef } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { TaskListTable, TaskListForm } from 'models/TaskList/components'

import './styles.css'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('program', 'Программа', SmartFilter.FilterSelect, {
    options: [
      { value: 'webvisitor', label: 'WebVisitor' },
      { value: 'monstro', label: 'Monstro' },
    ],
  }),
];


const TaskListMainPage = () => {
  const [pageContainer, setPageContainer] = useState(null);
  const tableRef = useRef(null);

  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <Page
      title={`Списки заданий`}
      mainActions={[
        { name: 'add', type: 'primary', action: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="taskList" onChange={onFilterChange} fields={filterFields} />}
      contentContainer={setPageContainer}
    >
      <SmartFilterValues queryKey="taskList" onChange={onFilterChange} fields={filterFields} container={pageContainer} />

      <TaskListTable onEdit={onEdit} ref={tableRef} />

      <TaskListForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && tableRef.current && tableRef.current.loadData({});
        }}
        {...editFormState}
      />
    </Page>
  )
}

export default TaskListMainPage
