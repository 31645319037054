import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Tabs } from 'antd'

import "./styles.css"


const PageHeaderTabs = ({ basePath, tabs, onTabChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(() => {
    const path = location.pathname.replace(basePath, '');
    let i = 0, n = tabs.length;
    while (i < n) {
      const tab = tabs[i];
      let match = tab.match || [];
      if (tab.key) match.push(tab.key);
      i++;

      let j = 0, k = match.length;
      while (j < k) {
        if (path.startsWith(match[j])) {
          return tab.key;
        }
        j++;
      }
    }
  }, [location.pathname, basePath, tabs]);

  const onChange = (key) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      const url = `${basePath}${key}`;
      navigate(url);
    }
  };

  return (
    <div className="pageHeaderTabsWrap">
      <Tabs className="pageHeaderTabs" activeKey={activeTab} items={tabs} onChange={onChange} animated={false} />
    </div>
  );
};


export default PageHeaderTabs;
