
export function getObjectParams(searchString, queryKey) {
  const query = new URLSearchParams(searchString).get(queryKey);
  let dataQuery = '';
  if (query) {
    dataQuery = query.slice(1, -1).replaceAll('|', '&');
  }
  return new URLSearchParams(dataQuery);
}


export function getDataQuery(searchParams, objectSearchParams, queryKey) {
  const dataQuery = objectSearchParams.toString();
  if (dataQuery) {
    searchParams.set(queryKey, `[${dataQuery.replaceAll('&', '|')}]`);
  } else {
    searchParams.delete(queryKey);
  }
  const searchString = decodeURIComponent(searchParams.toString());
  return [dataQuery, searchString]
}

export function combineDataQuery(dataQuery, filterParams) {
  if (!filterParams) return dataQuery;
  const params1 = new URLSearchParams(dataQuery);
  const params2 = new URLSearchParams(filterParams);
  for (let [key, val] of params2.entries()) {
    params1.set(key, val);
  }
  return params1.toString();
}

