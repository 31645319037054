import { useSelector } from 'react-redux'
import { TreeSelect } from 'antd'


const CategorySelect = ({ filter, disabled, required, multiple, ...props }) => {
  const items = useSelector(state => state.category.forSelectItems);

  return (
    <TreeSelect
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Выберите категорию"
      showSearch={true}
      allowClear={true}
      disabled={disabled}
      //optionFilterProp="label"
      filterTreeNode={(input, treeNode) => treeNode.props.name.toLowerCase().indexOf(input.toLowerCase()) > -1}
      size="large"
      onChange={props.onChange}
      treeData={items}
      fieldNames={{ label: 'name', value: 'slug', children: 'children' }}
      multiple={multiple}
      //treeCheckable={true}
      //showCheckedStrategy={TreeSelect.SHOW_ALL}
      {...props}
    />
  );
}

export default CategorySelect
