import { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react'

import WalkSiteApi from 'models/WalkSite/api'
import { TableList, TableColumns } from 'components/common'


const defaultColumnList = ['url', 'title', 'status', 'isActive', 'syncedAt', 'actions'];


const WalkSiteLinkTable = forwardRef(({ columnList, domain, links, onEdit }, ref) => {
  const tableRef = useRef(null);

  const [objects, setObjects] = useState([]);
  const [objectList, setObjectList] = useState([]);

  const [walkSiteUpdate, walkSiteUpdateProps] = WalkSiteApi.endpoints.walkSiteUpdate.useMutation();

  const updateState = (links) => {
    const _links = { ...links };
    const _list = Object.keys(_links).map(url => _links[url]);
    setObjects(_links);
    setObjectList(_list);
  }

  useEffect(() => {
    updateState(links);
    // eslint-disable-next-line
  }, [links]);

  useEffect(() => {
    if (walkSiteUpdateProps?.isSuccess) {
      updateState(walkSiteUpdateProps?.data?.links);
    }
    // eslint-disable-next-line
  }, [walkSiteUpdateProps?.isSuccess]);

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const getList = (dataQuery) => {
    const searchParams = new URLSearchParams(dataQuery);

    let params = {};
    for (const [key, value] of searchParams.entries()) {
      if (key === 'isActive') {
        params[key] = value === 'true';
      } else {
        params[key] = value;
      }
    }

    const _list = Object.keys(objects).map(url => objects[url]);
    const newList = _list.filter((item) => {
      let res = true;
      if (params.isActive !== undefined) {
        res = params.isActive ? item.isActive : !item.isActive;
      }

      if (params.search) {
        res = item.url.includes(params.search)
      }

      return res;
    });
    setObjectList(newList);
  }

  const onDelete = (url) => {
    console.log('url', url);
    let _links = { ...objects };
    delete _links[url];

    walkSiteUpdate({ domain, links: _links });
  }

  const batchDelete = (selected) => {
    console.log('selected', selected);
    let _links = { ...objects };
    selected.forEach(url => {
      delete _links[url];
    });

    walkSiteUpdate({ domain, links: _links });
  }

  const updateIsActive = (data) => {
    let _links = { ...objects };
    const oldLink = _links[data.url];
    _links[data.url] = { ...oldLink, ...data };

    walkSiteUpdate({ domain, links: _links });
  }

  const fields = {
    url: { title: 'Ссылка', sorter: false, ellipsis: true },
    domain: { title: 'Домен', sorter: false, width: 160 },
    title: { title: 'Анкор', sorter: false },
    status: { title: 'Статус', sorter: false, width: 100 },
    syncedAt: TableColumns.getColumnDateTime({ title: 'Cинхр.', fieldName: "syncedAt" }),
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', fieldName: 'isActive', primaryField: "url", itemUpdate: updateIsActive, itemUpdateProps: walkSiteUpdateProps }),
    actions: TableColumns.getColumnActions({ onEdit, onDelete, primaryField: "url" }),
  };

  const loadData = ({ dataQuery = null, searchString = null }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      //pagination={pagination}
      queryKey="walkSiteLink"
      rowKey="url"
      tableLayout="fixed"

      onEdit={onEdit}
      getList={getList}
      batchDelete={batchDelete}

      rowSelection={true}
    />
  );
});

export default WalkSiteLinkTable;
