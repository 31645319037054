import { FormFields } from 'components/common'

const { TextField, SelectField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {

  return (
    <>
      <TextField label="Название" name="name" required={true} />

      <SelectField
          label="Программа"
          name="program"
          required={true}
          options={[
            { value: 'webvisitor', label: 'WebVisitor' },
            { value: 'monstro', label: 'Monstro' },
          ]}
        />
    </>
  );
};

export default MainFieldset;
