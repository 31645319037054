import { Button, Form, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { FormFields } from 'components/common'
import { CategorySelectField } from 'models/Category/components'
import { CitySelectField } from 'models/City/components'

const { TextField, NumberField, SwitchField, RadioField } = FormFields;



const MainFieldset = ({ detailForEdit, initials }) => {
  // filters: {city, traffic__gt, https, ipv6, ga, ym}

  return (
    <>
      <SwitchField label="Активно" name="isActive" />

      <TextField label="Название" name="name" required={true} />

      <Form.List name="categories">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space.Compact key={key} style={{ display: 'flex', marginBottom: 0 }} align="center">
                <div style={{ flex: 1 }}>
                  <CategorySelectField {...restField} placeholder="Категория" name={[name, 'category']} required={true} />
                </div>

                <NumberField placeholder="Процент" name={[name, 'percent']} required={true} />

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px', height: '40px' }}>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              </Space.Compact>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить категорию
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <CitySelectField label="Город" name="cityCode__in" required={false} multiple={true} />

      <NumberField label="Посещяемость >" name="traffic__gt" required={false} />

      <RadioField label="Яндекс Метрика" name="ym__isnotnull" required={false} options={[{ label: 'Все', value: "" }, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }]} />

      <RadioField label="IPV6" name="ipv6" required={false} options={[{ label: 'Все', value: "" }, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }]} />
    </>
  );
};

export default MainFieldset;
