import { useSelector } from 'react-redux'

import { DetailValue } from 'components/common'


const CategoryValue = ({ detail, asLink, isBack, style, suffix = "" }) => {
  const bySlug = useSelector(state => state.category.bySlug);
  const cachedItem = bySlug[detail.code];

  const name = detail?.name || cachedItem?.name || detail.code;

  return (
    <>
      <DetailValue
        linkUrl={asLink && `/category/${detail.code}/`}
        isBack={isBack}
        render={() => (
          <div style={style}>
            {name}{suffix}
          </div>
        )}
      />
    </>
  );
};


export default CategoryValue
