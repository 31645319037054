import PageHeader from './PageHeader'
import './styles.css'
import './page_transitions.css'


const Page = ({ children, contentContainer, ...headerProps }) => {
  let cls = "page";
  if (headerProps.tabs) cls += " page__witTabs";

  return (
    <div className={cls}>
      <PageHeader {...headerProps} />

      <div className="pageContent" ref={contentContainer}>{children}</div>
    </div>
  );
};

export default Page
