import { Form, Input } from 'antd'

import "./styles.css"


const TextField = ({ name, label, required, disabled, maxLength = 255 }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Input size="large" disabled={disabled} maxLength={maxLength} />
    </Form.Item>
  );
};

export default TextField;
