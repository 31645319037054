import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  detail: null,
  detailForEdit: null,
};

export const siteSlice = createSlice({
  initialState,
  name: 'site',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.siteGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.siteGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.siteGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.siteGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.siteGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.siteUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default siteSlice.reducer;
