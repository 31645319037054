import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Название" name="name" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
