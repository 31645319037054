import { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Table } from 'antd'

import { getObjectParams, getDataQuery, combineDataQuery } from 'utils/urls'
import TableRawActions from './TableRawActions'

import './styles.css'


function getSort(field, orderField) {
  if (orderField === field) return 'ascend';
  if (orderField === `-${field}`) return 'descend';
  return false;
}

function getSortField(field, order) {
  if (order === 'ascend') return field;
  if (order === 'descend') return `-${field}`;
  return false;
}


const TableList = forwardRef(({
  queryKey,
  rowKey,
  filterParams,
  fields,
  columnList,
  objectList,
  pagination,
  tableLayout,

  getList,
  getListProps,
  itemUpdate,
  itemUpdateProps,
  itemDelete,
  itemDeleteProps,
  batchDelete,
  batchDeleteProps,

  rowActions,
  rowSelection,
  hideStat,
  expandable,
  sticky,
}, ref) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);

  const searchParams = new URLSearchParams(location.search);

  const objectSearchParams = useMemo(() => {
    return getObjectParams(location.search, queryKey);
  }, [location.search, queryKey]);

  const orderField = objectSearchParams.get('sort');

  const columns = useMemo(() => {
    return columnList.reduce((result, fieldName) => {
      let config = fields[fieldName];
      if (config) {
        result.push({ ...config, key: fieldName, dataIndex: fieldName, sortOrder: getSort(fieldName, orderField) })
      }
      return result
    }, []);
  }, [columnList, fields, orderField]);

  const onBatchDelete = (selected) => {
    batchDelete && batchDelete(selected);
    setSelected([]);
  }

  function loadData({ dataQuery = null, searchString = null, changeNav = true }) {
    if (dataQuery === null || searchString === null) {
      [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
    }
    if (changeNav) navigate({ search: searchString }, { replace: true });
    getList && getList(combineDataQuery(dataQuery, filterParams));
  }
  useImperativeHandle(ref, () => ({ loadData }));

  const onTableChange = (pagination, filters, sorter) => {
    const sort = getSortField(sorter.field, sorter.order);
    if (sort) {
      objectSearchParams.set('sort', getSortField(sorter.field, sorter.order));
    } else {
      objectSearchParams.delete('sort');
    }

    if (pagination.current && pagination.current > 1) {
      objectSearchParams.set('page', pagination.current);
    } else {
      objectSearchParams.delete('page');
    }

    loadData({});
  };

  useEffect(() => {
    loadData({ changeNav: false });
    // eslint-disable-next-line
  }, []);

  const params = filterParams ? Object.keys(filterParams).map(k => filterParams[k]) : [];

  useEffect(() => {
    loadData({ changeNav: false });
    // eslint-disable-next-line
  }, params);

  useEffect(() => {
    if (itemUpdateProps?.isSuccess || itemDeleteProps?.isSuccess || batchDeleteProps?.isSuccess) {
      loadData({});
    }
    // eslint-disable-next-line
  }, [itemUpdateProps?.isSuccess, itemDeleteProps?.isSuccess, batchDeleteProps?.isSuccess]);

  const _rowActions = (rowActions || []).concat([
    {
      title: 'Удалить',
      icon: 'delete',
      type: 'danger',
      action: (selected) => onBatchDelete(selected),
    },
  ]);

  return (
    <div className="tableList">
      <TableRawActions
        rowActions={_rowActions}
        selected={selected}
      />

      {!hideStat && pagination && (
        <div className="tableStat">Показано c {pagination.start} по {pagination.end} из {pagination.count}</div>
      )}

      <Table
        tableLayout={tableLayout || "auto"}
        sticky={sticky}
        columns={columns}
        dataSource={objectList || []}
        rowKey={rowKey || "id"}
        loading={getListProps?.isLoading}
        pagination={
          pagination?.page ? {
            pageSize: pagination.size,
            total: pagination.count,
            current: pagination.page,
            hideOnSinglePage: true,
            showSizeChanger: false
          } : false
        }
        onChange={onTableChange}
        expandable={expandable}

        rowSelection={rowSelection && {
          selectedRowKeys: selected,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRowKeys.slice());
          },
        }}
      />
    </div>
  );
});


export default TableList;
