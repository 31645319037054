import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  byCode: {},
  forSelectItems: [],

  detail: null,
  detailForEdit: null,
};

export const citySlice = createSlice({
  initialState,
  name: 'city',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.cityGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.cityGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload.results;
        state.pagination = action.payload.pagination;
      })

      .addMatcher(api.endpoints.cityGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.cityGetItemsForSelect.matchFulfilled, (state, action) => {
        const items = action.payload || [];
        state.forSelectItems = items;
        state.byCode = items.reduce((acc, item) => {
          acc[item.code] = item;
          return acc
        }, {})
      })

      .addMatcher(api.endpoints.cityGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.cityGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.cityUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default citySlice.reducer;
