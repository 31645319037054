import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import AuthApi from 'redux/api/authApi'
import CategoryApi from 'models/Category/api'
import CountryApi from 'models/Country/api'
import CityApi from 'models/City/api'
import TaskListApi from 'models/TaskList/api'
import WalkSiteApi from 'models/WalkSite/api'


const initialState = {
  dataIsLoaded: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    initCompleted: (state) => {
      state.dataIsLoaded = true;
    },
  },
});

export default appSlice.reducer;


const appInit = createAsyncThunk(
  'app/init',
  async (arg, thunkAPI) => {
    const tokenOld = thunkAPI.getState().auth.token;
    if (tokenOld) {
      await thunkAPI.dispatch(AuthApi.endpoints.tokenRefresh.initiate(tokenOld));
      console.log('appInit: load initials data');

      await thunkAPI.dispatch(CategoryApi.endpoints.categoryGetItemsForSelect.initiate());
      await thunkAPI.dispatch(CountryApi.endpoints.countryGetItemsForSelect.initiate());
      await thunkAPI.dispatch(CityApi.endpoints.cityGetItemsForSelect.initiate());
      await thunkAPI.dispatch(TaskListApi.endpoints.taskListGetItemsForSelect.initiate());
      await thunkAPI.dispatch(WalkSiteApi.endpoints.walkSiteGetItemsForSelect.initiate());

      await thunkAPI.dispatch(appSlice.initCompleted());

      return true;
    }
    return false;
  }
)


export { appInit }
