import React from 'react'
import { Row, Col } from 'antd'

import { FormFields } from 'components/common'

const { FileUploadField } = FormFields;


const MainFieldset = ({ detailForEdit, initials }) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <FileUploadField
          name="file" 
          required={true}
          title="Выбрать файл"
          accept=".txt"
          mode="single"
          beforeUpload={(file) => {
            return false;
          }}
        />
      </Col>
    </Row>
  );
};

export default MainFieldset;
