import { Form } from 'antd'
import { useSelector } from 'react-redux'

import CategorySelect from '../CategorySelect'


const CategorySelectField = ({ name, label, required, disabled, multiple, filter, onChange }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CategorySelect required={required} disabled={disabled} filter={filter} multiple={multiple} onChange={onChange} />
    </Form.Item>
  );
}

CategorySelectField.getQueryParams = (values) => {
  return values.join(',')
}


CategorySelectField.getValue = (values) => {
  if (Array.isArray(values) && values.length <= 1) values = values[0] && values[0].split(',');
  if (!values) return undefined;
  return values;
}

const DisplayValue = ({ value }) => {
  const bySlug = useSelector(state => state.category.bySlug);
  const cachedItem = bySlug[value];

  return cachedItem?.name || value;
};

CategorySelectField.DisplayValue = DisplayValue;


export default CategorySelectField
