import { useEffect } from 'react'
import { Row, Col, Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { Fieldset, Descriptions, OnOff, BoolValue, DateTimeValue } from 'components/common'
import WalkSiteApi from 'models/WalkSite/api'
import { CountryValue } from 'models/Country/components'
import { CityValue } from 'models/City/components'
import { CategoryValue } from 'models/Category/components'


const WalkSiteDetail = ({ detail }) => {
  const [itemUpdate, itemUpdateProps] = WalkSiteApi.endpoints.walkSiteUpdate.useMutation();
  const [getDetail] = WalkSiteApi.endpoints.walkSiteGetDetail.useLazyQuery();

  const countryByCode = useSelector(state => state.country.byCode);
  const cityByCode = useSelector(state => state.city.byCode);

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getDetail(detail?.id);
    }
    // eslint-disable-next-line
  }, [detail?.id, itemUpdateProps.isSuccess]);

  const country = detail?.countryCode ? countryByCode[detail?.countryCode] || { code: detail?.countryCode }: null;
  const city = detail?.cityCode ? cityByCode[detail?.cityCode] || { code: detail?.cityCode } : null;

  const schema = detail?.https ? 'https' : 'http';
  const url = `${schema}://${detail?.domain}`;

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset title="Основное">
          <Descriptions gutter={[16, 16]}>
            <Descriptions.Item label="Сайт" span={21}>
              {url}
              <Button size='small' style={{ marginLeft: '8px' }} onClick={() => { window.open(url, '_blank', 'noreferrer') }}>
                <LinkOutlined />
              </Button>
            </Descriptions.Item>

            <Descriptions.Item label="Страна" md={24}>
              {country && <CountryValue detail={country} asLink={true} isBack={true} />}
            </Descriptions.Item>

            <Descriptions.Item label="Город" md={24}>
              {city && <CityValue detail={city} asLink={true} isBack={true} />}
            </Descriptions.Item>

            <Descriptions.Item label="Категории">
              {(detail?.categories || []).map(v => <CategoryValue key={`category${v}`} detail={{ code: v }} asLink={true} isBack={true} />)}
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions>
            <Descriptions.Item label="Активно">
              <OnOff detail={detail} fieldName="isActive" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
            </Descriptions.Item>

            <Descriptions.Item label="HTTPS">
              <BoolValue value={detail?.https} />
            </Descriptions.Item>

            <Descriptions.Item label="IPV6">
              <BoolValue value={detail?.ipv6} />
            </Descriptions.Item>

            <Descriptions.Item label="Посещяемость">{detail?.traffic}</Descriptions.Item>

            <Descriptions.Item label="Yandex Метрика">{detail?.ym}</Descriptions.Item>
            <Descriptions.Item label="Google Analytics">{detail?.ga}</Descriptions.Item>

            <Descriptions.Item label="Дата изменения ссылок">
              <DateTimeValue date={detail?.linksChangedAt} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
}

export default WalkSiteDetail
