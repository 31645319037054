import UserPanel from 'components/UserPanel'
import TopTabs from './TopTabs'

import './styles.css'


const Top = ({ title, basePath, tabs, onTabChange }) => {
  return (
    <header className="top">
      <div className="top__start">
        {title && <div className="topTitle">{title}</div>}
      </div>

      <div className="top__middle">
        {tabs && <TopTabs basePath={basePath} tabs={tabs} onTabChange={onTabChange} />}
      </div>

      <div className="top__end">
        <UserPanel />
      </div>
    </header>
  );
}


export default Top;
