import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  items: [],
  pagination: {},

  bySlug: {},
  forSelectItems: [],

  detail: null,
  detailForEdit: null,
};

export const categorySlice = createSlice({
  initialState,
  name: 'category',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.categoryGetItems.matchPending, (state, action) => {
        state.items = [];
      })
      .addMatcher(api.endpoints.categoryGetItems.matchFulfilled, (state, action) => {
        state.items = action.payload;
        //state.pagination = action.payload.pagination || {};
      })

      .addMatcher(api.endpoints.categoryGetItemsForSelect.matchFulfilled, (state, action) => {
        const items = action.payload || [];
        state.forSelectItems = items;
        state.bySlug = items.reduce((acc, item) => {
          acc[item.slug] = item;
          if (item.children) {
            item.children.forEach(child => {
              acc[child.slug] = child;
            });
          }
          return acc
        }, {})
      })

      .addMatcher(api.endpoints.categoryGetDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.categoryGetForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.categoryGetForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.categoryUpdate.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default categorySlice.reducer;
