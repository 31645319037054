import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { api } from './api/api'
import appSlice from './features/appSlice'
import authReducer from './features/authSlice'

import siteSlice from 'models/Site/siteSlice'
import taskListSlice from 'models/TaskList/taskListSlice'
import taskSlice from 'models/Task/taskSlice'
import configListSlice from 'models/ConfigList/configListSlice'
import country from 'models/Country/slice'
import city from 'models/City/slice'
import category from 'models/Category/slice'
import walkSite from 'models/WalkSite/slice'
import walkSiteList from 'models/WalkSiteList/slice'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appSlice,
  auth: authReducer,

  site: siteSlice,
  taskList: taskListSlice,
  task: taskSlice,
  configList: configListSlice,
  country,
  city,
  category,
  walkSite,
  walkSiteList,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    thunk,
    api.middleware, 
  ]
})

export const persistor = persistStore(store)
