import { api } from 'redux/api/api'


const ConfigListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    configListGetItems: builder.query({
      query: (params = null) => ({
        url: 'configlist/',
        params: params,
      }),
    }),
    configListGetDetail: builder.query({
      query: (id, params) => ({
        url: `configlist/${id}/`,
      }),
    }),
    configListGetForEdit: builder.query({
      query: (id) => ({
        url: `configlist/${id}/?forEdit=1`,
      }),
    }),

    configListCreate: builder.mutation({
      query: (values) => ({
        url: 'configlist/',
        method: 'POST',
        body: values,
      }),
    }),
    configListUpdate: builder.mutation({
      query: (values) => ({
        url: `configlist/${values.slug}/`,
        method: 'PUT',
        body: values,
      }),
    }),
    configListDelete: builder.mutation({
      query: (id) => ({
        url: `configlist/${id}/`,
        method: 'DELETE',
      }),
    }),
    configListBatchDelete: builder.mutation({
      query: (selected) => ({
        url: 'configlist/delete/',
        method: 'POST',
        body: { selected },
      }),
    }),

    configListUpload: builder.mutation({
      query: ({ slug, data }) => ({
        url: `configlist/${slug}/upload/`,
        method: 'POST',
        body: data,
        formData: true,
      }),
    }),
  }),
})


export default ConfigListApi;
