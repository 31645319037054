import { useState, useRef } from 'react'

import { Page } from 'layout'
import { SmartFilter, SmartFilterValues } from 'components/common'
import { CategoryTable, CategoryForm, CategoryCombineForm } from 'models/Category/components'


const filterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const CategoryMainPage = () => {
  const [pageContainer, setPageContainer] = useState(null);
  const tableRef = useRef(null);

  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  const [combineFormState, setCombineFormState] = useState({});

  const onCombine = (selected) => {
    setCombineFormState({ visible: true, selected });
  };

  const onFilterChange = ({ dataQuery, searchString }) => {
    tableRef.current.loadData({ dataQuery, searchString });
  }

  return (
    <Page
      title={`Категории`}
      mainActions={[
        { name: 'add', action: (detail) => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="category" onChange={onFilterChange} fields={filterFields} />}
      contentContainer={setPageContainer}
    >
      <SmartFilterValues queryKey="category" onChange={onFilterChange} fields={filterFields} container={pageContainer} />

      <CategoryTable ref={tableRef} onEdit={onEdit} onCombine={onCombine} />

      <CategoryForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && tableRef.current.loadData({});
        }}
        {...editFormState}
      />

      <CategoryCombineForm
        onClose={(isUpdated) => {
          setCombineFormState({});
          isUpdated && tableRef.current.loadData({});
        }}
        {...combineFormState}
      />
    </Page>
  )
}

export default CategoryMainPage
