import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import CategoryApi from 'models/Category/api'

import MainFieldset from './MainFieldset'


const CategoryCombineForm = ({ visible, objectId, initials, onClose, selected }) => {
  const bySlug = useSelector(state => state.category.bySlug);
  const [getDetail, getDetailProps] = CategoryApi.endpoints.categoryGetForEdit.useLazyQuery();
  const [combine, combineProps] = CategoryApi.endpoints.categoryCombine.useMutation();

  const onFinish = (values) => {
    const data = { ...values, selected};
    console.log(data);

    //combine(data)
  };

  const initialValues = {
    ...initials
  };

  let name = '';
  if (selected && selected.length) {
    const item = bySlug[selected[0]];
    name = item.name;
  }

  return (
    <ModalForm
      name="category"
      withTabs={false}
      title={`Объединение категорий`}
      width={700}
      visible={visible}

      primaryField="slug"
      objectId={objectId}
      //detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      //createProps={createProps}
      updateProps={combineProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail, name }
      }}
    >
      <MainFieldset initials={initials} />
    </ModalForm>
  );
}


export default CategoryCombineForm
