import { useSelector } from 'react-redux'

import { ModalForm } from 'components/common'
import CategoryApi from 'models/Category/api'

import MainFieldset from './MainFieldset'


const CategoryForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.category.detailForEdit);
  const [getDetail, getDetailProps] = CategoryApi.endpoints.categoryGetForEdit.useLazyQuery();
  const [create, createProps] = CategoryApi.endpoints.categoryCreate.useMutation();
  const [update, updateProps] = CategoryApi.endpoints.categoryUpdate.useMutation();

  const onFinish = (values) => {
    console.log(values);
    if (objectId) {
      update(values);
    } else {
      create(values);
    }
  };

  const initialValues = {
    ...initials
  };

  return (
    <ModalForm
      name="category"
      withTabs={false}
      title={`Редактирование категории ${objectId || ''}`}
      width={700}
      visible={visible}

      primaryField="slug"
      objectId={objectId}
      detailForEdit={detailForEdit}
      getDetail={getDetail}
      getDetailProps={getDetailProps}
      createProps={createProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initialValues}

      prepareFormValues={(detail) => {
        return { ...detail }
      }}
    >
      <MainFieldset detailForEdit={detailForEdit} initials={initials} />
    </ModalForm>
  );
}


export default CategoryForm
